import { IDivergencia } from '.'
import { DivergenciaActionTypes } from './actionTypes'

export const setDivergencias = (payload: IDivergencia) => ({
  type: DivergenciaActionTypes.SET_DIVERGENCIAS,
  payload
})

export const updateObservacao = (payload: string | null) => ({
  type: DivergenciaActionTypes.UPDATE_OBSERVACAO,
  payload
})
