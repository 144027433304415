import React from 'react'
import "./Loader.scss"

export function Loader() {
  return (
    <div className="container">
      <div className="progress-bar"></div>
    </div>
  )
}
export default Loader
