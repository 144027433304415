import { Reducer } from 'redux'
import { converterData } from '../../helpers/helpers'
import { MinhasContagensActionsTypes } from './actionTypes'

export interface DadosUsuario {
  codLoja: string | undefined,
  dataInicio: string | undefined,
  existeDivergencia: boolean | undefined,
  id: string | undefined,
  nomeFuncionario: string | undefined,
  nomeLoja: string | undefined,
  tipoContagem: string | undefined
}

export interface OutrasContagensAtivas {
  outrasContagens: {
    items: Array<DadosUsuario>,
    limit: number,
    totalItems: number,
    totalPages: number,
  }
}

const estadoInicial = {
  outrasContagens: {
    items: [],
    limit: 5,
    totalItems: 0,
    totalPages: 0
  }
}

const BuscarOutrasContagens: Reducer<OutrasContagensAtivas> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case MinhasContagensActionsTypes.BUSCAR_OUTRAS_CONTAGENS: {
      return {
        ...action.payload,
        items: action.payload?.items?.map((dados: any) => ({...dados, dataInicio: converterData(dados.dataInicio)}))
      }
    }
    default:
      return state
  }
}

export default BuscarOutrasContagens
