import React, {useEffect, useRef, useState} from 'react'
import {InputAdornment, TextField} from '@material-ui/core'
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { useField } from '@unform/core'
import './InputDate.scss'

type Props = {
  label?: string
  name: string
  placeholder?: string
  className?: string
}

export function DatePicker(props: Props) {
  const { label, name, placeholder, className } = props

  const dataReferencial = useRef(null)

  const {fieldName, registerField, defaultValue, error} = useField(name)
  const [date, setDate] = useState({})

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: dataReferencial.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <div className="Container-DatePicker">
      <TextField
        type="date"
        className={className}
        label={label}
        fullWidth
        inputRef={dataReferencial}
        defaultValue={defaultValue}
        SelectProps={date}
        placeholder={placeholder}
        onChange={(event: any) => setDate({data: event.target.value})}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <TodayOutlinedIcon/>
            </InputAdornment>
          ),
        }}
        {...props}
      />
      {error && <span className="Span-DatePicker" >{error}</span>}
    </div>
  )
}

export default DatePicker
