import React from 'react'
import api from '../../../../infraestructure/api'
import { Box, Collapse, ListItemText, ListItem, List, TextField, Typography, Button  } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import './BotaoObservacao.scss'
import { useDispatch, useSelector } from 'react-redux'
import { IDivergencia } from '../../../../redux/Divergencia'
import { contagemId } from '../../../../infraestructure/auth'
import { updateObservacao } from '../../../../redux/Divergencia/actions'
import notificaAirbrake from '../../../../config/Airbrake'
import { useTranslation } from 'react-i18next'

export function BotaoObservacao() {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { observacao, codEquipamento, descricaoEquipamento } = useSelector((state: IDivergencia) => state.divergencia)

  const [abrir, setAbrir] = React.useState(false)
  const [campoDesabilitado, setCampoDesabilitado] = React.useState(false)
  const [editando, setEditando] = React.useState(false)
  const [adicionarOuEditar, setAdicionarOuEditar] = React.useState(false)
  const [observacaoTextArea, setObservacaoTextArea] = React.useState('')

  let url = `/ccl-api-cadastros/ativos/contagem-cega/v1/contagens/${contagemId()}/equipamentos/${codEquipamento}/observacoes`

  React.useEffect(() => {
    if (observacao !== null) {
      setObservacaoTextArea(observacao)
      dispatch(updateObservacao(observacao))
      setCampoDesabilitado(true)
      setAdicionarOuEditar(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observacao])

  const adicionarObservacao = () => {
    observacaoTextArea !== '' &&
    api.put(url, {"observacao": observacaoTextArea})
      .then(() => {
        dispatch(updateObservacao(observacaoTextArea))
        setCampoDesabilitado(true)
        setAdicionarOuEditar(true)
      })
      .catch((erro) => {
        notificaAirbrake(
          `ErroAdicionaObservacao: ${erro}`,
          'erro',
          `BotaoAdicionarObservacao, idContagem: ${contagemId()}, idEquipamento: ${codEquipamento}, equipamento: ${descricaoEquipamento}`
        )
      })
  }

  const excluirObservacao = () => {
    api.put(url, {"observacao": null})
      .then(() => {
        setObservacaoTextArea('')
        dispatch(updateObservacao(null))
        setCampoDesabilitado(false)
        setAdicionarOuEditar(false)
        setEditando(false)
      })
      .catch((erro) => {
        notificaAirbrake(
          `ErroExcluiObservacao: ${erro}`,
          'erro',
          `BotaoExcluirObservacao, idContagem: ${contagemId()}, idEquipamento: ${codEquipamento}, equipamento: ${descricaoEquipamento}`
        )
      })
  }

  const editarObservacao = () => {
    setCampoDesabilitado(false)
    setEditando(true)
  }

  const salvarObservacao = () => {
    observacaoTextArea !== '' &&
    api.put(url, {"observacao": observacaoTextArea})
      .then(() => {
        dispatch(updateObservacao(observacaoTextArea))
        setCampoDesabilitado(true)
        setEditando(false)
      })
      .catch((erro) => {
        notificaAirbrake(
          `ErroSalvaObservacao: ${erro}`,
          'erro',
          `BotaoSalvarObservacao, idContagem: ${contagemId()}, idEquipamento: ${codEquipamento}, equipamento: ${descricaoEquipamento}`
        )
      })
  }

  return (
    <Box className="container-btn-observacao">
      <List className="lista-observacao">
        <ListItem button onClick={() => setAbrir(!abrir)} className="list-item-obs">
          <ListItemText primary={t("botao_observacao.observacao")} />
          {abrir ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </ListItem>
        <Collapse in={abrir} className="collapse-observacao">
          <TextField
            variant='outlined'
            multiline
            rows={98 / 21}
            inputProps={{
              maxLength: 70,
            }}
            className="text-field-observacao"
            value={observacaoTextArea}
            disabled={campoDesabilitado}
            label={t("botao_observacao.observacao")}
            onChange={(event) => setObservacaoTextArea(event.target.value)}
          />
          <Typography className="contador-observacao">{observacaoTextArea.length}/70</Typography>
          <Box className="box-botoes-obs">
            {adicionarOuEditar ?
              <>
                {editando ?
                  <Button className="btn-adicionar-obs" onClick={salvarObservacao}>{t("botao_observacao.btn_salvar")}</Button> :
                  <>
                    <Button className="btn-excluir-obs" onClick={excluirObservacao}>{t("botao_observacao.btn_excluir")}</Button>
                    <Button className="btn-editar-obs" onClick={editarObservacao}>{t("botao_observacao.btn_editar")}</Button>
                  </>
                }
              </> :
              <Button className="btn-adicionar-obs" onClick={adicionarObservacao}>
                {t("botao_observacao.btn_adicionar")}
              </Button>
            }
          </Box>
        </Collapse>
      </List>
    </Box>
  )
}

export default BotaoObservacao
