import DateFnsUtils from "@date-io/date-fns"
import React from 'react'
import { Provider } from 'react-redux'
import store from '../redux/store'
import Rotas from './Rotas'
import './App.scss'
import Tema from './Tema'
import ReactGA from '../config/ReactGA'
import { BrowserRouter } from 'react-router-dom'
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import pt from 'date-fns/locale/pt-BR'
import es from 'date-fns/locale/es'
import { ErrorBoundary } from '../components/ErrorBoundary'

const App = () => {
  const languageDefault = navigator.language

  ReactGA()
  return (
    <ErrorBoundary>
      <Tema>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={languageDefault === 'pt-BR' ? pt : es}>
          <Provider store={store}>
            <BrowserRouter>
              <Rotas />
            </BrowserRouter>
          </Provider>
        </MuiPickersUtilsProvider>
      </Tema>
    </ErrorBoundary>
  )
}

export default App
