import { Reducer } from 'redux'
import { QrCodeItem } from '../../screens/QrCode/products'
import { QRCodeActionTypes } from './actionTypes'

const qrCodeState: Reducer<any> = (state = [], action: any) => {

  switch (action.type) {
    case QRCodeActionTypes.ADICIONA_POR_CODIGO: {  
      const qrCode = action.qrCode
      const newItems = state.itensPrevistos.map((item: QrCodeItem) => {
        if(item.qrCodeId === qrCode){
          item.quantidadeContada = 1
          return item
        } else {
          return item
        }
      })

      state.itensPrevistos = newItems

      return state
    }
    case QRCodeActionTypes.ATUALIZA_LISTA:
      return action.items
    default:
      return state
  }
}

export default qrCodeState
