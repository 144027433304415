import { PaginaInterface } from '.'
import { PaginaAtualActionTaypes } from './actionTypes'

export const atualizaPaginaHistorico = (payload: PaginaInterface | any) => ({
  type: PaginaAtualActionTaypes.PAGINA_ATUAL_HISTORICO,
  payload
})

export const atualizaPaginaEncerradas = (payload: PaginaInterface | any) => ({
  type: PaginaAtualActionTaypes.PAGINA_ATUAL_ENCERRADAS,
  payload
})

export default {}
