import { Reducer } from 'redux'
import { NovoEquip } from './actionTypes'

export interface InterfaceNovoEquip {
    codEquipamento: string
    codGrupo: string
    codSubgrupo: string
    descricaoEquipamento: string
    possuiNumeroSerie: boolean | undefined
    nomeGrupo: string
    nomeSubgrupo: string
    quantidadeAfastada: number
    quantidadeAguardandoDevolucao: number
    quantidadeComprometida: number
    quantidadeContada: number
    quantidadeDisponivel: number
    quantidadeNoSistema: number
    quantidadeReservada: number
    quantidadeSubstituiu: number
}

const estadoInicial: InterfaceNovoEquip = {
    codEquipamento: '',
    codGrupo: '',
    codSubgrupo: '',
    descricaoEquipamento: '',
    possuiNumeroSerie: undefined,
    nomeGrupo: '',
    nomeSubgrupo: '',
    quantidadeAfastada: 0,
    quantidadeAguardandoDevolucao: 0,
    quantidadeComprometida: 0,
    quantidadeContada: 0,
    quantidadeDisponivel: 0,
    quantidadeNoSistema: 0,
    quantidadeReservada: 0,
    quantidadeSubstituiu: 0,
}

const NovoEquipamento: Reducer<InterfaceNovoEquip> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case NovoEquip.NovoEquip: {
      return action.payload
    }
    default:
      return state
  }
}

export default NovoEquipamento
