import React from 'react'
//Servicos
import { apiContagemAtiva } from '../../../services/MinhasContagens'
import { apiContagemEmAndamento } from '../../../services/ContagemEmAndamento'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { buscarContagemEmAndamento } from '../../../redux/EmAndamento/action'
import { notificaAirbrake } from '../../../config/Airbrake'
//Helpers
import { modificarCodigo, ordenarListaPorNomeGrupo, ordenarListaPorNome, ordenarListaPorQtd, esperar } from '../../../helpers/helpers'
import { Itens, Equipamento } from './interface'
//Componentes
import { Card, CardContent, List, ListItem, Typography, Checkbox, Button, TextField, Select, MenuItem, Dialog, IconButton, CircularProgress, Box } from '@material-ui/core'
import { BotaoAdicionarEquipamento } from './ButtonAdicionarEquipamento'
import { DescartarContagem } from './ButtonDescartarContagem'
import { FinalizarContagem } from './ButtonFinalizarContagem'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import './ContagemAndamento.scss'
import Transicao from '../../ComponenteTransicao'

export function EmAndamento() {

  const {t} = useTranslation()
  const dispatch = useDispatch()

  const novoEquip = useSelector((state: any) => state)
  const [abrirModal, setAbrirModal] = React.useState(false)
  const [equipSendoContado, setEquipSendoContado] = React.useState<Equipamento | any>([])
  const [equipamentos, setEquipamentos] = React.useState<Itens | any>()
  const [listaDeBusca, setListaDeBusca] = React.useState<Itens | any>()
  const [ordenarPor, setOrdenarPor] = React.useState('descricaoEquipamento')
  const [equipamentoInformado, setEquipamentoInformado] = React.useState("")
  const [buscando, setBuscando] = React.useState(false)
  const localEquipamentos = useSelector(({ qrCodeState }: any) => qrCodeState)

  let quantidadeInformada = 0
  const opcoesOrdenacao = [
    { label: t("ordenacao.nome"), value: 'descricaoEquipamento', id: 1 },
    { label: t("ordenacao.grupo"), value: 'nomeGrupo', id: 2 },
    { label: t("ordenacao.quantidade"), value: 'quantidadeContada', id: 3 }
  ]

  React.useEffect(() => {
    const carregarContagemAtiva = async () => {
      apiContagemAtiva()
        .then(resposta => {
          const { data } = resposta
          if(data.id !== undefined) {
            apiContagemEmAndamento(data.id, data.codLoja)
              .then(resposta => {
                const { data } = resposta
                let porNome = data.itensPrevistos?.sort(ordenarListaPorNome)

                setEquipamentos(data)
                setListaDeBusca({...data, itensPrevistos: porNome})
                dispatch(buscarContagemEmAndamento({...data, itensPrevistos: porNome}))
              })
          }
        })
        .catch(error => {
          notificaAirbrake(`ChamadaApiDetalhesContagemEmAndamento: ${error}`, 'error', 'ContagemEmAndamento')
          setBuscando(false)
        })
    }
    const itemsSize = localEquipamentos?.itensPrevistos?.length ?? 0
    if(itemsSize === 0) {
      carregarContagemAtiva()
    } else {
      const data = localEquipamentos
      let porNome = data.itensPrevistos?.sort(ordenarListaPorNome)

      setBuscando(false)
      setEquipamentos(data)
      setListaDeBusca({...data, itensPrevistos: porNome})
      dispatch(buscarContagemEmAndamento({...data, itensPrevistos: porNome}))
    }

  }, [dispatch])

  React.useEffect(() => {
    if(novoEquip.novoEquipamento.codEquipamento !== '' || undefined) {
      let listaComNovoItem = listaDeBusca?.itensPrevistos?.concat([novoEquip.novoEquipamento])
      setListaDeBusca({...listaDeBusca, itensPrevistos: listaComNovoItem?.sort(ordenarListaPorNome)})
      setEquipamentos({...equipamentos, itensPrevistos: listaComNovoItem?.sort(ordenarListaPorNome)})
      setOrdenarPor("descricaoEquipamento")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novoEquip.novoEquipamento.codEquipamento])

  const abreModal = (equip: any) => {
    setEquipSendoContado(equip)
    setAbrirModal(true)
  }

  const fecharModal = () => {
    setAbrirModal(false)
  }

  const mudarQuantidade = (event: React.ChangeEvent<HTMLInputElement>) => {
    const equipSelecionado = equipamentos?.itensPrevistos.findIndex((obj: any) => obj.codEquipamento === event.target.value)
    const updateEquip = { ...equipamentos?.itensPrevistos[equipSelecionado!]}

    const equipFiltrado = listaDeBusca?.itensPrevistos.findIndex((obj: any) => obj.codEquipamento === event.target.value)
    const updateEquipFiltrado = { ...listaDeBusca?.itensPrevistos[equipFiltrado!]}

    if(updateEquip?.quantidadeContada === 1 || updateEquipFiltrado?.quantidadeContada === 1) {

      const mudaQuantidade = {...updateEquip, quantidadeContada: 0}
      const mudaQtdEquipFiltrado = {...updateEquipFiltrado, quantidadeContada: 0}

      const updateLista = [
        ...equipamentos?.itensPrevistos.slice(0, equipSelecionado!),
        mudaQuantidade,
        ...equipamentos?.itensPrevistos.slice(equipSelecionado! + 1)
      ]

      const updateListaFiltrada = [
        ...listaDeBusca?.itensPrevistos.slice(0, equipFiltrado!),
        mudaQtdEquipFiltrado,
        ...listaDeBusca?.itensPrevistos.slice(equipFiltrado! + 1)
      ]
      setListaDeBusca({...listaDeBusca, itensPrevistos: updateListaFiltrada})
      setEquipamentos({...equipamentos, itensPrevistos: updateLista})
      dispatch(buscarContagemEmAndamento({...equipamentos, itensPrevistos: updateLista}))
    } else {
      const mudaQuantidade = {...updateEquip, quantidadeContada: 1}
      const mudaQtdEquipFiltrado = {...updateEquipFiltrado, quantidadeContada: 1}

      const updateLista = [
        ...equipamentos?.itensPrevistos.slice(0, equipSelecionado!),
        mudaQuantidade,
        ...equipamentos?.itensPrevistos.slice(equipSelecionado! + 1)
      ]
      const updateListaFiltrada = [
        ...listaDeBusca?.itensPrevistos.slice(0, equipFiltrado!),
        mudaQtdEquipFiltrado,
        ...listaDeBusca?.itensPrevistos.slice(equipFiltrado! + 1)
      ]
      setListaDeBusca({...listaDeBusca, itensPrevistos: updateListaFiltrada})
      setEquipamentos({...equipamentos, itensPrevistos: updateLista})
      dispatch(buscarContagemEmAndamento({...equipamentos, itensPrevistos: updateLista}))
    }
  }

  const quantidadeDigitada = (event: React.ChangeEvent<HTMLInputElement>) => {
    quantidadeInformada = Number(event.target.value)

    return quantidadeInformada
  }

  const confirmarQuantidade = (codigo: string) => {
    setAbrirModal(false)
    const equipSelecionado = equipamentos?.itensPrevistos.findIndex((obj: any) => obj.codEquipamento === codigo)
    const updateEquip = { ...equipamentos?.itensPrevistos[equipSelecionado!], quantidadeContada: quantidadeInformada}

    const equipFiltrado = listaDeBusca?.itensPrevistos.findIndex((obj: any) => obj.codEquipamento === codigo)
    const updateEquipFiltrado = { ...listaDeBusca?.itensPrevistos[equipFiltrado!], quantidadeContada: quantidadeInformada}

    const updateLista = [
      ...equipamentos?.itensPrevistos.slice(0, equipSelecionado!),
      updateEquip,
      ...equipamentos?.itensPrevistos.slice(equipSelecionado! + 1)
    ]

    const updateListaEquipFiltrado = [
      ...listaDeBusca?.itensPrevistos.slice(0, equipFiltrado!),
      updateEquipFiltrado,
      ...listaDeBusca?.itensPrevistos.slice(equipFiltrado! + 1)
    ]
    setListaDeBusca({...listaDeBusca, itensPrevistos: updateListaEquipFiltrado})
    setEquipamentos({...equipamentos, itensPrevistos: updateLista})
    dispatch(buscarContagemEmAndamento({...equipamentos, itensPrevistos: updateLista}))
  }

  const buscarEquipamento = async (event?: React.ChangeEvent<HTMLInputElement | any>) => {
    event!.preventDefault()
    setBuscando(true)
    setEquipamentoInformado(event!.target.value.toLowerCase())
    let equipEncontrado: any = []

    if(equipamentoInformado !== '') {
      equipEncontrado = equipamentos?.itensPrevistos.filter((equipamentos: any) => equipamentos.descricaoEquipamento.toLowerCase().includes(equipamentoInformado))
      setListaDeBusca({...listaDeBusca, itensPrevistos: equipEncontrado})
      await esperar(1500)
      setBuscando(false)
    } else if(equipamentoInformado.length < 2 || '') {
      setListaDeBusca({...listaDeBusca, itensPrevistos: equipamentos?.itensPrevistos})
    }
  }

  const ordenarLista = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOrdenarPor(event.target.value as string)
    let opEscolhida = event.target.value as string
    let listaOrdenada:any = []
    const listaEquip = listaDeBusca?.itensPrevistos

    switch (opEscolhida || ordenarPor) {
      case "descricaoEquipamento":
        listaOrdenada = listaEquip?.sort(ordenarListaPorNome)
        setListaDeBusca({...listaDeBusca, itensPrevistos: listaOrdenada})
        break;
      case "nomeGrupo":
        listaOrdenada = listaEquip?.sort(ordenarListaPorNomeGrupo)
        setListaDeBusca({...listaDeBusca, itensPrevistos: listaOrdenada})
        break;
      case "quantidadeContada":
        listaOrdenada = listaEquip?.sort(ordenarListaPorQtd)
        setListaDeBusca({...listaDeBusca, itensPrevistos: listaOrdenada})
        break;
      default:
        listaOrdenada = listaEquip?.sort(ordenarListaPorNome)
        setListaDeBusca({...listaDeBusca, itensPrevistos: listaOrdenada})
    }
  }

  const limparCampoDeBusca = async () => {
    setEquipamentoInformado("")
    setBuscando(true)
    await esperar(1000)
    setListaDeBusca({ ...listaDeBusca, itensPrevistos: equipamentos?.itensPrevistos })
    setBuscando(false)
  }

  return (
    <Box className="Contagem-Andamento-Container-Lista">
      <div className="Busca-Equipamento-Andamento">
        <TextField
          className="Campo-Filtro-Equip-Nome"
          variant="standard"
          onChange={buscarEquipamento}
          fullWidth
          value={equipamentoInformado}
          placeholder={t("contagens.placeholder_equip")}
          InputProps={{
            endAdornment: (
              <>
                {equipamentoInformado === "" ?
                  <IconButton onClick={buscarEquipamento} disabled={equipamentoInformado === ""}>
                    <SearchIcon />
                  </IconButton> :
                  <IconButton onClick={limparCampoDeBusca}>
                    <ClearIcon />
                  </IconButton>
                }
              </>
            ),
          }}
        />
      </div>
      <div className="Contagem-Andamento-Filtro">
        <Select
          value={ordenarPor}
          onChange={ordenarLista}
          className="Contagem-Andamento-Filtro-Ordenacao"
        >
          {opcoesOrdenacao.map((op) => (
            <MenuItem key={op.id} value={op.value}>{op.label}</MenuItem>
          ))}
        </Select>
      </div>
      <div className="Contagem-Andamento-Adicionar">
        <BotaoAdicionarEquipamento dadosContagem={equipamentos}/>
      </div>
      {listaDeBusca?.itensPrevistos === undefined ?
        <div className="Loader-Equipamentos">
          <CircularProgress
            size={60}
            variant="indeterminate"
            className="Progresso-Circular-Andamento"
          />
        </div>
        :
        <>
          {buscando === true ?
            <div className="Loader-Equipamentos">
              <CircularProgress
                size={60}
                variant="indeterminate"
                className="Progresso-Circular-Andamento"
              />
            </div>
            :
            <List component="div" disablePadding className="Contagem-Andamento-Lista">
              {listaDeBusca?.itensPrevistos !== undefined && listaDeBusca?.itensPrevistos?.map((itens: any) => (
                <ListItem disableGutters divider key={Math.random()} className="ListItem-Contagem-Andamento">
                  <Card className="Card-Contagem-Andamento">
                    <CardContent className="CardContent-Contagem-Andamento">
                      <div className="Dados-Contagem-Andamento">
                        <Typography className="Descricao-Equip">{itens?.descricaoEquipamento}</Typography>
                        <div className="Nome-Grupo-Codigo">
                          <Typography className="Codigo-Equip">{modificarCodigo(itens?.codEquipamento)}</Typography>
                          <Typography className="Nome-Grupo-Equip">{itens?.nomeGrupo}</Typography>
                        </div>
                      </div>
                      <div>
                        {itens.possuiNumeroSerie === true ?
                          <Checkbox
                            className="Check-Qtd"
                            onChange={mudarQuantidade}
                            value={itens?.codEquipamento}
                            checked={itens?.quantidadeContada === 1}
                            color="primary"
                          /> :
                          <Button onClick={() => abreModal(itens)} className="Botao-Contar-Equipamento">{itens?.quantidadeContada}</Button>
                        }
                      </div>
                    </CardContent>
                  </Card>
                </ListItem>
              ))}
            </List>
          }
        </>
      }
      <div className="Opcoes-Descarte-Finalizar">
        <DescartarContagem dadosContagem={equipamentos}/>
        <FinalizarContagem dadosContagem={equipamentos}/>
      </div>
      <Dialog
        open={abrirModal}
        onClose={fecharModal}
        TransitionComponent={Transicao}
        className="Dialog-info-quantidade"
      >
        <Button className="botao-arrastar-fechar" onClick={fecharModal}></Button>
        <Typography className="Informativo-Quantidade">{equipSendoContado?.descricaoEquipamento}</Typography>
        <Typography className="Alerta-Quantidade">{t("modal_contagem.quantidade")}</Typography>
        <TextField
          name="quantidadeContada"
          variant="outlined"
          type="number"
          autoFocus
          className="Input-Quantidade-Contada"
          onChange={quantidadeDigitada}
        />
        <div className="Acoes-Contagem-Equipamento">
          <Button
            className="Confirmar-Quantidade"
            onClick={() => confirmarQuantidade(equipSendoContado?.codEquipamento)}
          >
            {t("modal_contagem.confirmar")}
          </Button>
          <Button className="Cancelar-Quantidade" onClick={fecharModal}>
            Cancelar
          </Button>
        </div>
      </Dialog>
    </Box>
  )
}

export default EmAndamento
