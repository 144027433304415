import { AxiosPromise, AxiosRequestConfig } from 'axios';
import api from '../../infraestructure/api'

interface Error {
  message: Array<object>
}

export const resgatarContagemEmAndamento = (id_contagem?: string, cod_loja?: string):AxiosPromise<Object> => {
  const url = "ccl-api-cadastros/ativos/contagem-cega/v1/contagens/"
  const urlContagemEmAndamento = `${url}${id_contagem}/loja/grupo/${cod_loja}`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: urlContagemEmAndamento
  }

  return api(parametros);
}

export const apiContagemEmAndamento = async (idContagem?: string, codLoja?: string) => {
  try {
    const resposta = await resgatarContagemEmAndamento(idContagem, codLoja!)

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export default {}
