import { Reducer } from 'redux'
import { PaginaAtualActionTaypes } from './actionTypes'

export interface PaginaInterface {
  pagina: {
    paginaHistorico: number
    paginaEncerradas: number
  }
}

const estadoInicial: any = {
  paginaHistorico: 0,
  paginaEncerradas: 0
}

const AlterarPaginaHistorico: Reducer<PaginaInterface> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case PaginaAtualActionTaypes.PAGINA_ATUAL_HISTORICO: {
      return action.payload
    }
    case PaginaAtualActionTaypes.PAGINA_ATUAL_ENCERRADAS: {
      return action.payload
    }
    default:
      return state
  }
}

export default AlterarPaginaHistorico
