import { QRCodeActionTypes } from './actionTypes'

export const adicionarProduto = (qrCode: string) => ({
    type: QRCodeActionTypes.ADICIONA_POR_CODIGO,
    qrCode
})

export const atualizaLista = (items: any) => ({
    type: QRCodeActionTypes.ATUALIZA_LISTA,
    items
})

export default {}
