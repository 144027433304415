import React, { memo } from 'react';
//Servicos
import api from '../../../../infraestructure/api'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
//Componentes
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography, CircularProgress } from '@material-ui/core'
import Transicao from '../../../ComponenteTransicao'
import { SnackBar } from '../../../'
import CloseIcon from '@material-ui/icons/Close'
import './ButtonFinalizar.scss'
import {notificaAirbrake} from '../../../../config/Airbrake';

function ComponenteFinalizarContagem(dados: any) {

  const {t} = useTranslation()
  const { dadosContagem } = dados
  const history = useHistory()

  const [abrirModal, setAbrirModal] = React.useState(false)
  const [finalizando, setFinalizando] = React.useState(false)
  const [erroFinalizar, setErroFinalizar] = React.useState(false)
  const [abrirSnack, setAbrirSnack] = React.useState({ open: false, vertical: 'bottom', horizontal: 'center' })
  const [mensagem, setMensagem] = React.useState("")

  const { vertical, horizontal } = abrirSnack

  const finalizarContagem = async () => {

    if(window.navigator.onLine === false) {
      setMensagem(t("modal_conexao.finalizar"))
      setAbrirSnack({ ...abrirSnack, open: true })
    } else {
      setAbrirModal(true)
      setFinalizando(true)

      const url = `ccl-api-cadastros/ativos/contagem-cega/v1/contagens/${dadosContagem?.id}`

      api.patch(url, dadosContagem)
        .then(resposta => {
          const { status } = resposta
          if (status === 204) {
            const urlFinalizar = `${url}/finaliza`
            api.patch(urlFinalizar)
              .then(resposta => {
                const { status } = resposta
                if (status === 204) {
                  setFinalizando(false)
                }
              })
              .catch(error => {
                setMensagem(t("modal_conexao.erro_finalizar"))
                setErroFinalizar(true)
                setAbrirSnack({ ...abrirSnack, open: true })
                notificaAirbrake(`ChamadaApiFinalizarContagem: ${error}`, 'erro', 'ModalFinalizarContagem')
                setFinalizando(false)
              })
          }
        })
        .catch(error => {
          setMensagem(t("modal_conexao.erro_finalizar"))
          setErroFinalizar(true)
          setAbrirSnack({ ...abrirSnack, open: true })
          notificaAirbrake(`ChamadaApiAtualizarContagem: ${error}`, 'erro', 'ModalFinalizarContagem')
          setFinalizando(false)
        })
    }

  }

  const fecharDialog = () => {
    if(erroFinalizar === true) {
      setErroFinalizar(false)
      setAbrirModal(false)
      setFinalizando(false)
    } else {
      setAbrirModal(false)
      setFinalizando(false)
      history.push("/minhas-contagens")
    }
  }

  const fecharSnack = () => {
    setAbrirSnack({ ...abrirSnack, open: false })
  }

  return (
    <>
      <Button className="Botao-Finalizar" onClick={finalizarContagem}>Finalizar</Button>
      <Dialog
        open={abrirModal}
        onClose={fecharDialog}
        TransitionComponent={Transicao}
        className="Dialog-Finalizar-Contagem"
      >
        <DialogActions className="Dialog-Finalizar-Action">
          <Button className="Botao-Arrastar" onClick={fecharDialog} disabled={finalizando}></Button>
        </DialogActions>
        <DialogContent className="Dialog-Content-Finalizar-Contagem">
          <div className="Botao-Fechar-Finalizar">
            {finalizando ? "" :
              <IconButton className="Botao-X-Finalizar" onClick={fecharDialog} disabled={finalizando}>
                <HighlightOffIcon />
              </IconButton>
            }
          </div>
          <div className="Check-Menssagem">
            {finalizando ?
              <>
                <CircularProgress size={70} className="Circular-Progress"/>
                <Typography className="Encerrando-Contagem">{t("modal_finalizar.encerrando")}</Typography>
              </>
              :
              <>
                <CheckCircleSharpIcon className="Icon-Check" />
                <Typography className="Mensagem-Encerrada">{t("modal_finalizar.encerrada")}</Typography>
              </>
            }
          </div>
          {finalizando ? "" :
            <Button
              className="Botao-Concluir-Contagem"
              onClick={fecharDialog}
              disabled={finalizando}
            >
              Concluir
            </Button>
          }
        </DialogContent>
      </Dialog>
      <SnackBar
        className="Snackbar-Nova"
        open={abrirSnack.open}
        mensagem={mensagem}
        onClose={fecharSnack}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        action={
          <IconButton
            color="inherit"
            onClick={fecharSnack}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  )
}

export const FinalizarContagem = memo(ComponenteFinalizarContagem)
