import axios from "axios"

export const getEquipWithQrCode = async (contagemId: string, grupoId: string): Promise<any> => {
    const qrCode = `contagem-cega/${contagemId}/${grupoId}`
  
    return (await qrCodeAxiosInstance.get(qrCode)).data;
}

const obterQrCodeEndpointApi = () => {
  const qrCodeUrl = process.env.REACT_APP_QR_CODE_APP_API_BASE_URL
  if (!qrCodeUrl) {
    throw new Error('Não é possível realizar requisições. QR-Code Endpoint da API não está configurado.');
  }

  return qrCodeUrl;
};


const qrCodeAxiosInstance = axios.create({
  baseURL: obterQrCodeEndpointApi(),
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'X-Auth-Api': process.env.REACT_APP_CHAVE_AUTORIZACAO_API,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, HEAD, OPTIONS, DELETE',
  },
});