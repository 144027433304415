import React from 'react'
import { Grid } from '@material-ui/core'
import { VisualizacaoContagemEncerrada } from '../../components'
import './VizualizacaoContagem.scss'

export function VisualizacaoContagem() {

  return (
    <Grid container className="Visualizacao-Contagem">
      <Grid item className="Visualizacao-Contagem-Grid-Item">
        <VisualizacaoContagemEncerrada/>
      </Grid>
    </Grid>
  )
}

export default VisualizacaoContagem;
