import React from 'react'
//Services
import { apiBuscarNovosEquipamentos } from '../../../../services/Equipamentos'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { buscarContagemEmAndamento } from '../../../../redux/EmAndamento/action'
import { novoEquipamentoParaContagem } from '../../../../redux/NovoEquipamento/action'
import { notificaAirbrake } from '../../../../config/Airbrake'
//Helpers
import { isEmpty } from 'ramda'
import { esperar, modificarCodigo } from '../../../../helpers/helpers'
//Components
import { CardCarregamento, SnackBar } from '../../..'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import InfiniteScroll from 'react-infinite-scroller'
import Transicao from '../../../ComponenteTransicao'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography, TextField, List, ListItem, CircularProgress, Box } from '@material-ui/core'
import './BotaoAdicionar.scss'

interface DadosApi {
  items: Array<{
    codGrupo: string
    codSubgrupo: string
    codigo: string
    descricao: string
    nomeGrupo: string
    nomeSubgrupo: string
    possuiNumeroSerie: boolean | undefined
  }>
  limit: number
  totalItems: number
  totalPages: number
}

export function BotaoAdicionarEquipamento(dadosContagem?: any) {

  const {t} = useTranslation()
  const dispatch = useDispatch()
  let textoDigitado: string | any | undefined

  const [open, setOpen] = React.useState(false)
  const [abrirModal, setAbrirModal] = React.useState(false)
  const [novosEquip, setNovosEquip] = React.useState<DadosApi>([] as any)
  const [equip, setEquip] = React.useState({codigo: '', descricao: ''})
  const [contadorPagina, setContadorPagina] = React.useState(0)
  const [totalPaginas, setTotalPaginas] = React.useState(0)
  const [temMaisEquip, setTemMaisEquip] = React.useState(false)
  const [nomeEquip, setNomeEquip] = React.useState(textoDigitado)
  const [abrirSnack, setAbrirSnack] = React.useState(false)
  const [mensagem, setMensagem] = React.useState('')
  const [equipamentoInformado, setEquipamentoInformado] = React.useState('')
  const [buscando, setBuscando] = React.useState(false)

  const abrirTela = () => {
    setOpen(true)
  }

  const fecharTela = () => {
    setOpen(false)
  }

  const buscarEquipamento = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setBuscando(true)
    setEquipamentoInformado(event.target.value as string)

    if(equipamentoInformado !== '') {
      setContadorPagina(0)
      setTotalPaginas(0)

      apiBuscarNovosEquipamentos(equipamentoInformado, contadorPagina.toString())
        .then(resposta => {
          const { data } = resposta
          if(!isEmpty(data?.items)) {
            setContadorPagina(contadorPagina + 1)
            setTotalPaginas(data?.totalPages)
            setNovosEquip(data)

            setNomeEquip(equipamentoInformado)
            let temMaisEquipamento = contadorPagina <= totalPaginas && data?.totalItems > 10
            setTemMaisEquip(temMaisEquipamento)
          }
          setTimeout(() => {
            setBuscando(false)
          }, 1500)
        })
        .catch(erro => {
          notificaAirbrake(`ChamadaApiNovoEquipamento: ${erro}`, 'erro', 'ModalAdicionarMaisEquipamentos')
          setBuscando(false)
        })
    } else {
      setContadorPagina(0)
      setTotalPaginas(0)
      setTemMaisEquip(false)
      setNovosEquip([] as any)
    }
  }

  const carregarMaisEquipamentos = async () => {
    if(temMaisEquip && contadorPagina <= totalPaginas) {
      setTemMaisEquip(false)
      setContadorPagina(contadorPagina + 1)

      apiBuscarNovosEquipamentos(nomeEquip, contadorPagina.toString())
        .then(resposta => {
          const { data } = resposta
          if (!isEmpty(data?.items)) {
            let maisPagina = contadorPagina <= totalPaginas
            setTemMaisEquip(maisPagina)
            data.items.forEach((itens: any) => ({...novosEquip,  items: novosEquip?.items?.push(itens) }))
          }
        })
        .catch(erro => {
          notificaAirbrake(`ChamadaApiNovoEquipamento: ${erro}`, 'erro', 'ModalAdicionarMaisEquipamentos, CarregarMaisEquipamentos')
          setTemMaisEquip(false)
        })
    }
  }

  const abrirModalConfirmacao = (e: any) => {
    setAbrirModal(true)
    setEquip(e)
  }

  const fecharModalConfirmacao = () => {
    setAbrirModal(false)
  }

  const atualizarContagem = async (equipamento: any) => {
    const { codigo } = equipamento
    const { itensPrevistos } = dadosContagem.dadosContagem
    const equipamentoJaEstaNaLista = itensPrevistos?.find((item: any) => item?.codEquipamento === codigo)
    let novoEquip = {
      codEquipamento: equipamento?.codigo,
      descricaoEquipamento: equipamento?.descricao,
      nomeGrupo: equipamento?.nomeGrupo,
      nomeSubgrupo: equipamento?.nomeSubgrupo,
      codGrupo: equipamento?.codGrupo,
      codSubgrupo: equipamento?.codSubgrupo,
      possuiNumeroSerie: equipamento?.possuiNumeroSerie,
      quantidadeAfastada: 0,
      quantidadeAguardandoDevolucao: 0,
      quantidadeComprometida: 0,
      quantidadeContada: 0,
      quantidadeDisponivel: 0,
      quantidadeNoSistema: 0,
      quantidadeReservada: 0,
      quantidadeSubstituiu: 0,
    }

    if(equipamentoJaEstaNaLista !== undefined) {
      setMensagem(t("adicionar_equip.msg_equip_existente"))
      setAbrirSnack(true)
    } else {
      dispatch(buscarContagemEmAndamento({
        ...dadosContagem.dadosContagem,
        itensPrevistos: itensPrevistos.concat(novoEquip)
      }))
      dispatch(novoEquipamentoParaContagem(novoEquip))
      setAbrirModal(false)
      setOpen(false)
    }
  }

  const fecharSnack = () => {
    setAbrirSnack(false)
  }

  const limparCampoDeBusca = async () => {
    setEquipamentoInformado("")
    setBuscando(true)
    setContadorPagina(0)
    setTotalPaginas(0)
    setTemMaisEquip(false)
    setNovosEquip([] as any)
    await esperar(1000)
    setBuscando(false)
  }

  return (
    <>
      <Button
        endIcon={<AddIcon />}
        className="Botao-Adicionar-Equipamento"
        onClick={abrirTela}
      >
        {t("adicionar_equip.btn_add")}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transicao}
        keepMounted
        onClose={fecharTela}
        className="Container-Dialog-Adicionar"
      >
        <DialogActions className="Dialog-Actions-Adicionar">
          <Button className="Botao-Arrastar-Adicionar" onClick={fecharTela}></Button>
        </DialogActions>
        <DialogContent className="Dialog-Content-Adicionar">
          <div className="Dialog-Grid-Container-Adicionar">
            <div className="Dialog-Grid-Titulo-Botao-Adicionar">
              <Typography className="Dialog-Titulo-Novo">{t("adicionar_equip.novo_equip")}</Typography>
              <IconButton className="Dialog-Botao-Fechar-Adicionar" onClick={fecharTela}>
                <HighlightOffIcon />
              </IconButton>
            </div>
            <Grid item className="Dialog-Grid-Lista-Equip">
              <TextField
                className="Campo-Busca-Equipamento"
                variant="filled"
                onChange={buscarEquipamento}
                fullWidth
                value={equipamentoInformado}
                placeholder={t("contagens.placeholder_equip")}
                InputProps={{
                  endAdornment: (
                    <>
                      {equipamentoInformado === "" ?
                        <IconButton onClick={() => buscarEquipamento} disabled={equipamentoInformado === ""}>
                          <SearchIcon />
                        </IconButton> :
                        <IconButton onClick={limparCampoDeBusca}>
                          <ClearIcon />
                        </IconButton>
                      }
                    </>
                  )
                }}
              />
              <Typography className="Resultados">{`${novosEquip?.totalItems === undefined ? "0 Resultado" : novosEquip?.totalItems + " Resultados"}`}</Typography>
              {buscando === true ?
                <div className="Loader-Equipamentos">
                  <CircularProgress
                    size={60}
                    variant="indeterminate"
                    className="Progresso-Circular-Andamento"
                  />
                </div> :
                <div className="Lista-Container">
                  <InfiniteScroll
                    initialLoad={false}
                    loadMore={carregarMaisEquipamentos}
                    loader={
                      <div
                        key={0}
                      >
                        <CardCarregamento active={false} />
                      </div>
                    }
                    hasMore={temMaisEquip}
                    useWindow={false}
                  >
                    <List component="nav">
                      {novosEquip !== undefined && novosEquip?.items?.map((equip) => (
                        <ListItem button key={equip.codigo} className="ListItem-Novos-Equip" onClick={() => abrirModalConfirmacao(equip)}>
                          <Typography className="Nome-Equip">{equip.descricao}</Typography>
                          <Typography className="Cod-Equip">{modificarCodigo(equip.codigo)}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </InfiniteScroll>
                </div>
              }
              <Dialog
                open={abrirModal}
                onClose={fecharModalConfirmacao}
                className="Modal-Inserir-Novo-Equip"
              >
                <Button className="arrasta-fecha-novo-equip"></Button>
                <Typography className="Nome-Novo-Equip">{equip?.descricao}</Typography>
                <Typography className="Codigo-Novo-Equip">{modificarCodigo(equip?.codigo)}</Typography>
                <Box className="div-botoes-novo-equip">
                  <Button className="Adicionar-Novo-Equip" onClick={() => atualizarContagem(equip)}>{t("adicionar_equip.btn_add_modal")}</Button>
                  <Button className="cancelar-novo-equip" onClick={() => setAbrirModal(false)}>Cancelar</Button>
                </Box>
              </Dialog>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      <SnackBar
        className="Snack-Novo-Equip"
        open={abrirSnack}
        mensagem={mensagem}
        onClose={fecharSnack}
        autoHideDuration={8000}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        action={
          <IconButton
            color="inherit"
            onClick={fecharSnack}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  )
}

export default BotaoAdicionarEquipamento
