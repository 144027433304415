import React from 'react'
//Serviços
import { apiContagens } from '../../../../services/Contagens'
import { adicionarMaisContagensEncerradas, buscarMinhasContagensEncerradas } from '../../../../redux/MinhasContagens/encerradas/action'
import { useHistory } from 'react-router-dom'
import { MinhasContagensEncerradas, DadosUsuario } from '../../../../redux/MinhasContagens/encerradas'
import { useDispatch, useSelector } from 'react-redux'
import { PaginaInterface } from '../../../../redux/Paginas'
import { atualizaPaginaEncerradas } from '../../../../redux/Paginas/action'
import { notificaAirbrake } from '../../../../config/Airbrake'
//Helpers
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'
import { Filtro } from '../../../../redux/Filtro'
import { idFuncionario } from '../../../../infraestructure/auth'
//Estilos
import { Typography, ListItem, List, Card, CardContent, CardActionArea } from '@material-ui/core'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import InfiniteScroll from "react-infinite-scroller"
import { CardCarregamento } from '../../../'
import "./Encerradas.scss"

export function Encerradas() {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { contagensEncerradas } = useSelector((state: MinhasContagensEncerradas) => state)
  const { filtros } = useSelector((state: Filtro) => state)
  const { paginaEncerradas } = useSelector((state: PaginaInterface) => state.pagina)
  const [temMaisContagens, setTemMaisContagens] = React.useState(true)

  let paginasTotais = contagensEncerradas.totalPages

  React.useEffect(() => {
    dispatch(buscarMinhasContagensEncerradas({items: [], limit: 10, totalItems: 0, totalPages: 0}))
    dispatch(atualizaPaginaEncerradas({ paginaHistorico: 0, paginaEncerradas: 0}))

    const carregarContagensEncerradas = async () => {
      apiContagens(
        filtros.grupoLoja,
        filtros.fimPeriodoContagem,
        filtros.grupoEquipamentos,
        idFuncionario()!,
        filtros.grupoLoja,
        filtros.inicioPeriodoContagem,
        paginaEncerradas.toString(),
        "10",
        "FINALIZADO",
        filtros.tipoContagem
      ).then(resposta => {
        const { data } = resposta
        if (data?.totalItems <= 5 && contagensEncerradas.totalPages <= 1) {
          setTemMaisContagens(false)
        } else if (contagensEncerradas.items.length <= 5) {
          setTemMaisContagens(false)
        }
        dispatch(buscarMinhasContagensEncerradas(data))
      }).catch(error => {
        notificaAirbrake(`ChamadaApiContagemEncerrada: ${error}`, 'erro', 'MinhasContagensEncerradas')
        setTemMaisContagens(false)
      })

    }
    carregarContagensEncerradas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros])

  const carregarMaisContagens = async () => {
    if (paginaEncerradas <= paginasTotais) {
      setTemMaisContagens(false)

      await apiContagens(
        filtros.grupoLoja,
        filtros.fimPeriodoContagem,
        filtros.grupoEquipamentos,
        idFuncionario()!,
        filtros.grupoLoja,
        filtros.inicioPeriodoContagem,
        (paginaEncerradas + 1).toString(),
        "10",
        "FINALIZADO",
        filtros.tipoContagem
      ).then(resposta => {
        const { data } = resposta

        if (data.items.length !== 0 ) {
          let maisPagina = paginaEncerradas <= paginasTotais
          setTemMaisContagens(maisPagina)
          dispatch(adicionarMaisContagensEncerradas(data))
          dispatch(atualizaPaginaEncerradas({paginaHistorico: 0, paginaEncerradas: paginaEncerradas + 1}))
        } else {
          setTemMaisContagens(false)
        }
      }).catch(error => {
        notificaAirbrake(`ChamadaApiContagemEncerrada: ${error}`, 'erro', 'MinhasContagensEncerradas, CarregarMaisContagens')
        setTemMaisContagens(false)
      })
    }
  }

  const visualizarContagem = (contagem: any) => {
    localStorage.setItem("contagem_id", contagem.id)
    localStorage.setItem("loja_contagem_encerrada", contagem.codLoja)
    history.push("/visualizar-contagem")
  }

  return (
    <div className="Scroll-Infinita">
      <InfiniteScroll
        initialLoad={false}
        loadMore={carregarMaisContagens}
        loader={
          <div
            key={0}
          >
            <CardCarregamento active={false}/>
          </div>
        }
        hasMore={temMaisContagens}
        useWindow={false}
      >
        <List component="div" disablePadding>
          <>
            {contagensEncerradas?.items?.length === 0 && contagensEncerradas?.totalPages === 0 && temMaisContagens === false ?
              <Typography className="Sem-Contagem">{t("contagens.sem_encerradas")}</Typography>
              :
              !isEmpty(contagensEncerradas?.items) && contagensEncerradas?.items?.map((dados: DadosUsuario) => (
                <ListItem className="List-Cards-Encerradas" disableGutters divider key={dados?.id}>
                  <Card className="Cards">
                    <CardActionArea disabled={dados?.existeDivergencia === false} onClick={() => visualizarContagem(dados)}>
                      <CardContent>
                        <Typography className="Data-Contagem-Encerradas">
                          {dados?.dataInicio}
                        </Typography>
                        <Typography className="Nome-Codigo-Loja-Encerradas">{`${dados?.nomeLoja} / ${dados?.codLoja}`}</Typography>
                        <div className="linha-funcionario-seta">
                          <Typography
                            className="Nome-Usuario-Encerradas"
                          >
                            {dados?.nomeFuncionario?.toLowerCase().replace(/(?:^|\s)\S/g, function (text) {
                              return text?.toUpperCase();
                            })}
                          </Typography>
                          <KeyboardArrowRightIcon className="icone-seta-card"/>
                        </div>
                        <div className='linha-status-contagens-encerradas'>
                          {dados?.existeDivergencia ?
                            <Typography className="Com-Divergencia">{t("contagens.com_divergencia")}</Typography>
                            :
                            <Typography className="Sem-Divergencia">{t("contagens.sem_divergencia")}</Typography>
                          }
                          <Typography
                            className="Tipo-Contagem-Encerradas"
                          >
                            {dados?.tipoContagem === "PERSONALIZADA" ? t("contagens.personalizada") : t("contagens.geral")}
                          </Typography>
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </ListItem>
              ))
            }
          </>
        </List>
      </InfiniteScroll>
    </div>
  )
}

export default Encerradas
