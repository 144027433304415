import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly';
import contagemAtiva from './MinhasContagens/ativas'
import contagensEncerradas from './MinhasContagens/encerradas'
import historicoDeContagens from './HistoricoContagens'
import outrasContagens from './OutrasContagens'
import contagemEmAndamento from './EmAndamento'
import listaLojasNaRede from './LojasDaRede'
import novoEquipamento from './NovoEquipamento'
import filtros from './Filtro'
import tabCorrente from './TabCorrente'
import pagina from './Paginas'
import abrirEncerradas from './AbrirEncerradas'
import divergencia from './Divergencia'
import qrCodeState from './QrCode';

const reducers = combineReducers({
  contagemAtiva,
  contagensEncerradas,
  historicoDeContagens,
  outrasContagens,
  contagemEmAndamento,
  listaLojasNaRede,
  novoEquipamento,
  filtros,
  tabCorrente,
  pagina,
  abrirEncerradas,
  divergencia,
  qrCodeState
});

const store = createStore(
  reducers,
  undefined,
  devToolsEnhancer({ trace: true }),
);

export default store;
