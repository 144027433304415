import React, { memo, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import { MinhasContagens } from './MinhasContagens'
import { OutrasContagens } from './OutrasContagens'
import { HistoricoContagens } from './HistoricoContagens'
import { BotaoNovaContagem } from './ButtonNovaContagem'
import { HeaderContagens } from './HeaderContagens'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabUnstyled from '@mui/base/TabUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import './Tabs.scss'
import { useDispatch, useSelector } from 'react-redux'
import { tabCorrenteAtiva } from '../../redux/TabCorrente/action'
import { InterfaceTabCorrente } from '../../redux/TabCorrente';
import { Filtro } from '../Filtro';

function ComponenteTabsContagens() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { numeroTab } = useSelector((state: InterfaceTabCorrente) => state.tabCorrente)

  const mudarTab = (event: SyntheticEvent<Element, Event>, value: string | number | boolean) => {
    event.preventDefault()
    let tab = { numeroTab: value }
    dispatch(tabCorrenteAtiva(tab))
  }

  return (
    <Box className="Tab-Container">
      <HeaderContagens />
      <TabsUnstyled value={numeroTab} onChange={mudarTab} className="Tabs-unstyled">
        <Box className="Box-Titulo-Filtro">
          <div></div>
          <div>
            <Typography className="titulo-contagens">{t("contagens.contagens")}</Typography>
          </div>
          <div>
            <Filtro />
          </div>
        </Box>
        <TabsListUnstyled className="Tabs-list-unstyled">
          <TabUnstyled className="Tab-unstyled">{t("contagens.minhas")}</TabUnstyled>
          <TabUnstyled className="Tab-unstyled">{t("contagens.outras")}</TabUnstyled>
          <TabUnstyled className="Tab-unstyled">{t("contagens.historico")}</TabUnstyled>
        </TabsListUnstyled>
        {numeroTab === 0 &&
          <TabPanelUnstyled className="Tab-panel-unstyled" value={0} hidden={!numeroTab}>
            <MinhasContagens />
          </TabPanelUnstyled>
        }
        {numeroTab === 1 &&
          <TabPanelUnstyled className="Tab-panel-unstyled" value={1} hidden={!numeroTab}>
            <OutrasContagens />
          </TabPanelUnstyled>
        }
        {numeroTab === 2 &&
          <TabPanelUnstyled className="Tab-panel-unstyled" value={2} hidden={!numeroTab}>
            <HistoricoContagens />
          </TabPanelUnstyled>
        }
        <BotaoNovaContagem />
      </TabsUnstyled>
    </Box>
  )
}

export const TabsContagens = memo(ComponenteTabsContagens)
