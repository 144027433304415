import React from 'react';
import { Loader } from '..'
import { Card, CardContent, ListItem } from '@material-ui/core'
import './CardCarregamento.scss'

type Props = {
  active: boolean
}

export class CardCarregamento extends React.PureComponent<Props> {
  render() {

    const { active } = this.props

    return (
      <div className="Container-Esqueleto">
        <ListItem className="List-Cards-Esqueleto" disableGutters divider>
          {active === true ?
            <Card className="Card-Esqueleto">
              <CardContent>
                <div className="Primeira-Linha-Esqueleto">
                  <div className="Status-Esqueleto">
                    <Loader />
                  </div>
                  <div className="Data-Esqueleto">
                    <Loader />
                  </div>
                </div>
                <div className="Segunda-Linha-Esqueleto">
                  <Loader />
                </div>
                <div className="Terceira-Linha-Esqueleto">
                  <div>
                    <Loader />
                  </div>
                  <div>
                    <Loader />
                  </div>
                </div>
              </CardContent>
            </Card> :
            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
              <Card className="Card-Esqueleto">
                <CardContent>
                  <div className="Primeira-Linha-Esqueleto">
                    <div className="Status-Esqueleto">
                      <Loader />
                    </div>
                    <div className="Data-Esqueleto">
                      <Loader />
                    </div>
                  </div>
                  <div className="Segunda-Linha-Esqueleto">
                    <Loader />
                  </div>
                  <div className="Terceira-Linha-Esqueleto">
                    <div>
                      <Loader />
                    </div>
                    <div>
                      <Loader />
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className="Card-Esqueleto">
                <CardContent>
                  <div className="Primeira-Linha-Esqueleto">
                    <div className="Status-Esqueleto">
                      <Loader />
                    </div>
                    <div className="Data-Esqueleto">
                      <Loader />
                    </div>
                  </div>
                  <div className="Segunda-Linha-Esqueleto">
                    <Loader />
                  </div>
                  <div className="Terceira-Linha-Esqueleto">
                    <div>
                      <Loader />
                    </div>
                    <div>
                      <Loader />
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className="Card-Esqueleto">
                <CardContent>
                  <div className="Primeira-Linha-Esqueleto">
                    <div className="Status-Esqueleto">
                      <Loader />
                    </div>
                    <div className="Data-Esqueleto">
                      <Loader />
                    </div>
                  </div>
                  <div className="Segunda-Linha-Esqueleto">
                    <Loader />
                  </div>
                  <div className="Terceira-Linha-Esqueleto">
                    <div>
                      <Loader />
                    </div>
                    <div>
                      <Loader />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          }
        </ListItem>
      </div>
    )
  }
}

export default CardCarregamento
