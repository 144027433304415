import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import "./Corpo.scss"
import IconeChave from '../../../assets/IconeChave.svg'

export const Corpo: React.FC = () => {

  const {t} = useTranslation()
  const history = useHistory();

  const irParaClientes = () => {
    history.push('/minhas-contagens');
  }

  return (
    <List className="Corpo-Container">
      <ListItem
        component="li"
        button
        onClick={() => irParaClientes()}
      >
        <ListItemIcon>
          <img
            alt="Logo Casa do Construtor"
            src={IconeChave}
            className="Corpo-Icone"
          />
        </ListItemIcon>
        <ListItemText className="Corpo-Texto">
          <Typography>
            {t("menu.opcao_1")}
          </Typography>
        </ListItemText>
      </ListItem>
    </List>
  );
}

export default Corpo;
