function acessarValor(item: string) {
  let value = localStorage.getItem(item)
  if(value) {
    value = value.replace(/"/g, '')
  }

  return value
}

export const acessarToken = () => acessarValor("usuario-logado")
export const usuarioLogado = () => acessarValor("usuario")
export const lojaAtual = () => acessarValor("nome-loja")
export const codLoja = () => acessarValor("cod-loja")
export const idFuncionario = () => acessarValor("id-funcionario")
export const contagemId = () => acessarValor("contagem_id")
export const codLojaContagemEncerrada = () => acessarValor("loja_contagem_encerrada")
export const lojaPrincipal = () => acessarValor("loja")

export const sair = () => {
    sessionStorage.clear()
    localStorage.clear()
    window.location.href = "/"
}

export const estaAutenticado = () => {
  const token = acessarToken() && (usuarioLogado() !== null || undefined)
  if (token) {
    return true
  } else {
    return false
  }
}
