import React from 'react';
import Logo from '../../assets/Logo';
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import api from '../../infraestructure/api'
import { MenuLateral } from '../MenuLateral'
import { ModalNotificacao } from '..'
import { IconButton, Button, Box } from '@material-ui/core'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import './Header.scss'
import { atualizaPaginaHistorico } from '../../redux/Paginas/action';
import { useDispatch } from 'react-redux'
import notificaAirbrake from '../../config/Airbrake';

function ComponenteHeader(contagem?: any) {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const local = useLocation()
  const historicoNavegacao = useHistory()

  const urlAtualizarContagem = `ccl-api-cadastros/ativos/contagem-cega/v1/contagens/${contagem?.contagem?.id}`
  const [abrir, setAbrir] = React.useState(false)

  const fecharModal = () => {
    setAbrir(false)
  }

  const voltarPagina = (url?: string) => {
    dispatch(atualizaPaginaHistorico({ paginaHistorico: 0, paginaEncerradas: 0 }))

    switch (url) {
      case "/contagem-em-andamento":
        if(window.navigator.onLine === true) {
            api.patch(urlAtualizarContagem, contagem?.contagem)
              .then(resposta => {
                const { status } = resposta
                if (status === 204) {
                  historicoNavegacao.push('/minhas-contagens')
                }
              })
              .catch(erro => {
                notificaAirbrake(`ChamadaApiAtualizarContagem: ${erro}`, 'erro', 'BotaoVoltarContagemEmAndamento')
                historicoNavegacao.push('/minhas-contagens')
              })
        } else {
          setAbrir(true)
        }
        break;
      case "/visualizar-contagem":
        localStorage.removeItem("contagem_id")
        localStorage.removeItem("loja_contagem_encerrada")
        historicoNavegacao.push("/minhas-contagens")
        break;
      case "/detalhes-divergencia":
        historicoNavegacao.push("/visualizar-contagem")
        break;
      default:
        break;
    }
  }

  const renderizarMenusEsquerdo  = () => {
    switch (local.pathname) {
      case '/minhas-contagens': {
        return (<MenuLateral />)
      }
      case '/contagem-em-andamento': {
        return (
          <IconButton onClick={() => voltarPagina(local.pathname)} className="Botao-Voltar">
            <KeyboardArrowLeftIcon />
          </IconButton>
        )
      }
      case '/visualizar-contagem': {
        return (
          <IconButton onClick={() => voltarPagina(local.pathname)} className="Botao-Voltar">
            <KeyboardArrowLeftIcon />
          </IconButton>
        )
      }
      case '/detalhes-divergencia': {
        return (
          <IconButton onClick={() => voltarPagina(local.pathname)} className="Botao-Voltar">
            <KeyboardArrowLeftIcon />
          </IconButton>
        )
      }
      default:
        return (<div></div>)
    }
  }

  const renderizarMenusDireito = () => {
    switch (local.pathname) {
      case '/detalhes-divergencia': {
        return <MenuLateral/>
      }
      default:
        return (<div></div>)
    }
  }

  const voltarMinhasContagens = () => {
    setAbrir(false)
    historicoNavegacao.push("/minhas-contagens")
  }

  const continuarNaPagina = () => {
    setAbrir(false)
  }

  return (
    <header className="Header">
      <Box className="Menus-Header">
        {renderizarMenusEsquerdo()}
        {abrir === true &&
          <ModalNotificacao
            abrir={abrir}
            fechar={fecharModal}
            content={
              <div className="Botoes-Cancela-Continua">
                <Button className="Botao-Continuar" onClick={voltarMinhasContagens}>{t("modal_conexao.continuar")}</Button>
                <Button className="Botao-Cancelar" onClick={continuarNaPagina}>{t("modal_conexao.cancelar")}</Button>
              </div>
            }
          />
        }
      </Box>
      <Box className="Menus-Header">
        <Logo />
      </Box>
      <Box className="Menus-Header">
        {renderizarMenusDireito()}
      </Box>
    </header>
  )
}

export const Header = React.memo(ComponenteHeader)
