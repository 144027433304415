import React from 'react'
//Servicos
import { useTranslation } from 'react-i18next'
import api from '../../../../infraestructure/api'
import { useHistory } from 'react-router-dom'
import { apiLojasDaRede } from '../../../../services/Lojas'
import { codLoja, usuarioLogado, idFuncionario, lojaPrincipal } from '../../../../infraestructure/auth'
import { apiBuscarGrupoEquipamentos, apiBuscarGrupoEquipamentosSemDescricao } from '../../../../services/Equipamentos'
import * as Yup from 'yup'
import { notificaAirbrake } from '../../../../config/Airbrake'
//Componentes
import { Form } from '@unform/web'
import { MultipleSelect, Select, ChipFlutuante, SnackBar } from '../../..'
import { Button, Typography, IconButton, CircularProgress } from '@material-ui/core'
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined'
import CloseIcon from '@material-ui/icons/Close'
import './FormularioNovaContagem.scss'
//Helpers
import { isEmpty } from 'ramda'
import { esperar } from '../../../../helpers/helpers'

interface ChipData {
  key: number;
  label: string;
  value: string;
}

interface Error {
  error: string
  error_description: string
}

interface Lojas {
  label: string;
  value: string;
}

export function FormularioNovaContagem() {

  const formRef = React.useRef<any | null>(null)
  const { t } = useTranslation()
  const history = useHistory()

  const [emCarregamento, setEmCarregamento] = React.useState(false)
  const [opcao, setOpcao] = React.useState({ tipoContagem: "GERAL" })
  const [mensagemErro, setMensagemErro] = React.useState('')
  const [open, setOpen] = React.useState({ open: false, vertical: 'bottom', horizontal: 'center' })
  const [lojasDaRede, setLojasDaRede] = React.useState<Lojas[]>()

  React.useEffect(() => {
    const carregarLojasDaRede = async () => {
      apiLojasDaRede()
        .then(resposta => {
          const { data } = resposta

          setLojasDaRede(data?.map((lojas: any) => ({
            label: `${lojas?.numLoja} - ${lojas?.loja}`,
            value: `${lojas?.numLoja} - ${lojas?.loja}`
          })))
        })
        .catch(erro => {
          notificaAirbrake(`ChamadaApiLojasDaRede: ${erro}`, 'erro', 'FormularioNovaContagem')
        })
    }
    carregarLojasDaRede()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { vertical, horizontal } = open

  const validacaoFormNovaContagem = Yup.object().shape({
    codLoja: Yup.string().required(t("nova_contagem.cod_loja"))
  })

  const chipData: ChipData[] = [
    { key: 0, label: t("nova_contagem.chip_geral"), value: "GERAL" },
    { key: 1, label: t("nova_contagem.chip_personalizada"), value: "PERSONALIZADA" }
  ]

  const opcaoEscolhida = (chipEscolhido: ChipData) => {
    if (chipEscolhido.label === "Geral") {
      setOpcao({ tipoContagem: chipEscolhido.value })
    } else {
      setOpcao({ tipoContagem: chipEscolhido.value })
    }
  }

  const carregarEquipamentos = async (textoDigitado: string, opcoesPrevias: string | any[]) => {
    let pagina = parseInt("0", 10)
    let dados: any[] = []

    await esperar(2000)
    let opcoesFiltradas = []

    if (!textoDigitado) {
      const {data, code, errors}: any  = await apiBuscarGrupoEquipamentosSemDescricao(codLoja()!, "0", "100", "nomegru%2Casc")
      if(code === 200) {
        opcoesFiltradas = data?.items?.map((dados: any) => ({ value: dados.codigo, label: dados.descricao }))
      } else {
        notificaAirbrake(`ChamadaApiBuscarGrupoEquipamentosSemDescricao: ${errors}`, 'erro', 'FormularioNovaContagem')
      }
    } else {
      await esperar(1000)
      const textoEmMinusculo = textoDigitado.toLowerCase()
      await esperar(1000)

      if (textoEmMinusculo.length > 2) {
        const { data, code, errors }: any = await apiBuscarGrupoEquipamentos(codLoja()!, textoEmMinusculo, "0", "100", "nomegru%2Casc")
        if (pagina <= data.totalPages && code === 200) {
          for (let i = 0; i < data.totalPages; i++) {
            if (!isEmpty(data?.items)) {
              dados = data?.items?.map((dados: any) => ({ value: dados.codigo, label: dados.descricao }))
            }
          }
        } else {
          notificaAirbrake(`ChamadaApiBuscarGrupoEquipamentosSemDescricao: ${errors}`, 'erro', 'FormularioNovaContagem')
        }
      }

      opcoesFiltradas = dados.filter(({ label }: any) =>
        label.toLowerCase().includes(textoEmMinusculo)
      )
    }

    const hasMore = opcoesFiltradas.length > opcoesPrevias.length + 10;
    const slicedOptions = opcoesFiltradas.slice(
      opcoesPrevias.length,
      opcoesPrevias.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    };
  };

  const confirmarNovaContagem = async (dados: any) => {
    const url = "ccl-api-cadastros/ativos/contagem-cega/v1/contagens"
    let codigoLoja = dados?.codLoja.split('-')

    const dadosNovaContagem = {
      funcionario: {
        id: idFuncionario(),
        nome: usuarioLogado()
      },
      gruposEquipamentos: dados?.grupoEquipamentos !== undefined ? dados?.grupoEquipamentos : [],
      loja: {
        nome: `${codigoLoja?.[1]} - ${codigoLoja?.[2] ? codigoLoja?.[2] : ""}`.trim(),
        codGrupo: codigoLoja?.[0].trim(),
        codLoja: codigoLoja?.[0].trim(),
      },
      tipoContagem: opcao.tipoContagem
    }

    try {
      setEmCarregamento(true)
      await validacaoFormNovaContagem.validate(dados, { abortEarly: false })

      api.post(url, dadosNovaContagem, {headers: {'id-store': codLoja(),'id-tenant': codLoja()}})
        .then(resposta => {
          const { status } = resposta
          if (status === 201) {
            history.push("/contagem-em-andamento")
          }
        })
        .catch(erro => {
          const { status, data } = erro.response

          if (status === 401) {
            setMensagemErro(t("nova_contagem.erro_1"))
          } else if (status === 422) {
            setMensagemErro(t("nova_contagem.erro_2"))
          } else if (status === 500) {
            setMensagemErro(t("nova_contagem.erro_3"))
          } else {
            setMensagemErro(data?.errors?.[0]?.mensagemUsuario)
          }
          setOpen({ ...open, open: true })
          setEmCarregamento(false)
          notificaAirbrake(`ChamadaApiNovaContagem: ${data.errors?.[0].mensagemDesenvolvedor}`, 'erro', 'FormularioNovaContagem')
        })
    } catch (error: Error | any) {
      if (error instanceof Yup.ValidationError) {
        const message: any = {}

        error.inner.forEach(err => {
          message[err.path!] = err.message
        })

        formRef.current.setErrors(message)
      }
    }
  }

  const fecharSnack = () => {
    setOpen({ ...open, open: false })
  }

  return (
    <Form
      onSubmit={confirmarNovaContagem}
      ref={formRef}
      className="Dialog-Form-Nova"
    >
      <div className="Dialog-Corpo-Form">
        <Typography className="Loja-Form-Nova">{t("nova_contagem.loja")}</Typography>
        <Select
          name="codLoja"
          options={lojasDaRede}
          placeholder="Informe uma loja"
          defaultValue={{value: lojaPrincipal(), label: lojaPrincipal()}}
          hasIcon={true}
          icon={<StoreOutlinedIcon className="Icone-Busca-Select" />}
        />
        <Typography className="Tipo-Contagem-Form-Nova">{t("nova_contagem.tipo")}</Typography>
        <div className="Chips-Nova-Contagem">
          {chipData.map((data) => (
            <div key={data.key}>
              <ChipFlutuante
                label={data.label}
                select={opcao?.tipoContagem === data.value}
                onClick={() => opcaoEscolhida(data)}
              />
            </div>
          ))}
        </div>
        {opcao?.tipoContagem === "PERSONALIZADA" &&
          <>
            <Typography className="Grupo-Equip-Nova">{t("nova_contagem.grupo")}</Typography>
            <MultipleSelect
              name="grupoEquipamentos"
              placeholder={t("nova_contagem.placeholder")}
              loadingMessage={() => t("nova_contagem.carregar")}
              loadOptions={carregarEquipamentos}
              maxMenuHeight={200}
            />
          </>
        }
      </div>
      <div>
        {emCarregamento ?
          <CircularProgress
            size={50}
            variant="indeterminate"
            className="Progresso-Circular-Nova-Contagem"
          />
          :
          <Button
            type="submit"
            className="Finalizar-Nova"
            disabled={emCarregamento}
          >
            {t("nova_contagem.concluir")}
          </Button>
        }
      </div>
      <SnackBar
        className="Snackbar-Nova"
        open={open.open}
        mensagem={mensagemErro}
        onClose={fecharSnack}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        action={
          <IconButton
            color="inherit"
            onClick={fecharSnack}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Form>
  )
}
