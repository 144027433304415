import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { estaAutenticado, sair } from '../../infraestructure/auth'
import { PaginaInicial, Contagens, VisualizacaoContagem } from '../../screens';
import { DetalhesDivergencia } from '../../components';
import { useTranslation } from "react-i18next";
import '../../i18n'
import { Contagem } from '../../screens/Contagem';

interface PrivateRouteProps {
  component: any
  path?: string
  exact?: boolean
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={(routerProps) =>
        estaAutenticado() ?
          <Component {...routerProps} />
          : <Redirect to='/' />
      }
    />
  )
}

const Rotas = () => {

  const local = useLocation()
  const { i18n } = useTranslation();
  const autenticado = estaAutenticado()

  const pathName = local.pathname
  const languageDefault = navigator.language
  const dataDeExpiracao = Number(sessionStorage.getItem("data_exp")) > new Date().getTime()

  const mudarLinguagem = (language: string | undefined) => {
    i18n.changeLanguage(language)
  };

  useEffect(() => {
    mudarLinguagem(languageDefault)

    if(autenticado === true && dataDeExpiracao === false) {
      sair()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageDefault, dataDeExpiracao, autenticado])

  return (
    <>
      {
        autenticado && pathName === '/'
          ? <Redirect to='/minhas-contagens' />
          : !autenticado && <Redirect to='/' />
      }

      <Switch>
        <Route exact path="/" component={PaginaInicial} />
        <PrivateRoute exact path="/minhas-contagens" component={Contagens} />
        <PrivateRoute exact path="/contagem-em-andamento" component={Contagem}/>
        <PrivateRoute exact path="/visualizar-contagem" component={VisualizacaoContagem}/>
        <PrivateRoute exact path="/detalhes-divergencia" component={DetalhesDivergencia}/>
      </Switch>
    </>
  )
}

export default Rotas;
