import { AxiosPromise, AxiosRequestConfig } from 'axios';
import api from '../../infraestructure/api'
import { codLoja } from '../../infraestructure/auth'

interface Error {
  message: Array<object>
}

const codigoLoja = codLoja()

export const buscarLojasDaRede = (cod_loja: string):AxiosPromise<Object> => {
  const url = "ccl-api-lojas/v1/"
  const urlLojas = `${url}lojas?grupo=${cod_loja}`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: urlLojas
  }

  return api(parametros);
}

export const apiLojasDaRede = async (cod_loja?: string) => {

  const verificaSeTemCodigo = cod_loja ? cod_loja : codigoLoja

  try {
    const resposta = await buscarLojasDaRede(verificaSeTemCodigo!)

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export default {}
