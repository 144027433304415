import { AxiosPromise, AxiosRequestConfig } from 'axios';
import api from '../../infraestructure/api'
import { codLoja, idFuncionario } from '../../infraestructure/auth'

interface Error {
  message: Array<object>
}

const codigoLoja = codLoja()
const idUsuario = idFuncionario()

export const resgatarContagemAtiva = (cod_loja: string, id_usuario: string):AxiosPromise<Object> => {
  const urlContagens = `ccl-api-cadastros/ativos/contagem-cega/v1/contagens/ativas/lojas/grupos/${cod_loja}/funcionarios/${id_usuario}`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: urlContagens
  }

  return api(parametros);
}

export const resgatarContagemFinalizada = (cod_loja: string, id_usuario: string, page?: string, size?: string):AxiosPromise<Object> => {
  const urlContagens = `ccl-api-cadastros/ativos/contagem-cega/v1/contagens/loja/grupo/${cod_loja}/`
  const url = `${urlContagens}?idFuncionario=${id_usuario}&page=${page}&size=${size}&statusContagem=FINALIZADO`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: url
  }

  return api(parametros)
}

export const apiContagemAtiva = async () => {
  try {
    const resposta = await resgatarContagemAtiva(codigoLoja!, idUsuario!)

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export const apiContagensEncerradas = async (page?: string, size?: string) => {
  try {
    const resposta = await resgatarContagemFinalizada(codigoLoja!, idUsuario!, page, size)

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export default {}
