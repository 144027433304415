import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './bootstrap/App';
import { CircularProgress, Grid } from '@material-ui/core';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import "./Index.scss"

ReactDOM.render(
  <Suspense fallback={
    <Grid
      container
      className="Index-Suspense"
    >
      <CircularProgress
        size={50}
        variant="indeterminate"
        className="Progresso-Circular"
      />
    </Grid>
  }>
    <App />
  </Suspense>,
document.getElementById('root'));

serviceWorkerRegistration.register()
