import React from 'react'
import { useSelector } from 'react-redux'
import { IDivergencia } from '../../../../redux/Divergencia'
import { Transicao } from '../../../ComponenteTransicao'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Button, Box, Dialog, IconButton, Typography } from '@material-ui/core'
import './ModalMaisDetalhes.scss'

export function ModalMaisDetalhes() {

  const [open, setOpen] = React.useState(false)
  const {
    descricaoEquipamento,
    quantidadeDisponivel,
    quantidadeAfastada,
    quantidadeReservada,
    quantidadeSubstituiu,
    quantidadeComprometida,
    quantidadeAguardandoDevolucao
  } = useSelector((state: IDivergencia) => state.divergencia)

  return (
    <Box className="container-modal-mais-detalhe">
      <Button className="btn-modal-mais-detalhe" onClick={() => setOpen(true)}>Mais detalhes</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transicao}
        className="dialog-mais-detalhes"
      >
        <Button className="btn-arrasta-fecha-detalhes"></Button>
        <IconButton className="btn-icone-fechar" onClick={() => setOpen(false)}>
          <HighlightOffIcon />
        </IconButton>
        <Typography className="nome-equip-dialog">{descricaoEquipamento}</Typography>
        <ol className="list-modal">
          <li className="list-item-modal">
            <Typography className="chave-modal">Disponível:</Typography>
            <p className="valor-disponivel">{quantidadeDisponivel}</p>
          </li>
          <li className="list-item-modal">
            <Typography className="chave-modal">Afastada:</Typography>
            <p className="valor-modal">{quantidadeAfastada}</p>
          </li>
          <li className="list-item-modal">
            <Typography className="chave-modal">Reservada:</Typography>
            <p className="valor-modal">{quantidadeReservada}</p>
          </li>
          <li className="list-item-modal">
            <Typography className="chave-modal">Substituiu:</Typography>
            <p className="valor-modal">{quantidadeSubstituiu}</p>
          </li>
          <li className="list-item-modal">
            <Typography className="chave-modal">Comprometida:</Typography>
            <p className="valor-modal">{quantidadeComprometida}</p>
          </li>
          <li className="list-item-modal">
            <Typography className="chave-modal">Ag. Devolu.</Typography>
            <p className="valor-modal">{quantidadeAguardandoDevolucao}</p>
          </li>
        </ol>
      </Dialog>
    </Box>
  )
}

export default ModalMaisDetalhes
