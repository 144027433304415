import { Reducer } from 'redux'

enum AbrirEncerradasActionType {
  ABRIR_ENCERRADA = "ABRIR_ENCERRADA"
}

export interface InterfaceAbrirEncerrada {
  abrirEncerradas: {
    setAbrirEncerrada: boolean
  }
}

export const abrirEncerrada = (payload: InterfaceAbrirEncerrada | any) => ({
  type: AbrirEncerradasActionType.ABRIR_ENCERRADA,
  payload
})

const estadoInicial: any = {
    setAbrirEncerrada: false
}

const AbrirEncerradas: Reducer<InterfaceAbrirEncerrada> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case AbrirEncerradasActionType.ABRIR_ENCERRADA: {
      return action.payload
    }
    default:
      return state
  }
}

export default AbrirEncerradas
