import { Reducer } from 'redux'
import { MinhasContagensActionsTypes } from './actionTypes'
import { converterData } from '../../../helpers/helpers'

export interface MinhasContagensAtivas {
  contagemAtiva: {
    codLoja: string | undefined,
    dataInicio: string | undefined,
    existeDivergencia: boolean | undefined,
    id: string | undefined,
    nomeFuncionario: string | undefined,
    nomeLoja: string | undefined,
    tipoContagem: string | undefined
  },
}

const estadoInicial: any = {
  codLoja: '',
  dataInicio: '',
  existeDivergencia: false,
  id: '',
  nomeFuncionario: '',
  nomeLoja: '',
  tipoContagem: '',
}

const BuscarContagemAtivaReducer: Reducer<MinhasContagensAtivas> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case MinhasContagensActionsTypes.BUSCAR_CONTAGEM_ATIVA: {
      return {
        ...action.payload,
        dataInicio: converterData(action.payload?.dataInicio)
      }
    }
    default:
      return state
  }
}

export default BuscarContagemAtivaReducer
