import React from 'react'
//Services
import { contagemId, codLojaContagemEncerrada } from '../../../infraestructure/auth'
import { apiContagemEncerrada } from '../../../services/ContagemEncerrada'
import { setDivergencias } from '../../../redux/Divergencia/actions'
import { useTranslation } from 'react-i18next'
import { notificaAirbrake } from '../../../config/Airbrake'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
//Helpers
import { isEmpty } from 'ramda'
import { ContagemEncerrada, estadoInicial } from './interface'
import { ordenarListaPorNomeGrupo, ordenarListaPorNome, ordenarListaPorQtd, esperar, converterDataFusoLocal, modificarCodigo } from '../../../helpers/helpers'
//Componentes
import { Card, List, ListItem, MenuItem, Select, TextField, Typography, IconButton, CircularProgress, Box, CardActionArea, CardContent, Checkbox } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import { Header } from '../../Header'
import BotaoEnviarEmail from './BotaoEnviarEmail'
import './VisualizacaoContagemEncerrada.scss'

export function VisualizacaoContagemEncerrada() {

  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const idContagem = contagemId()
  const codigoLoja = codLojaContagemEncerrada()

  const [contagemEncerrada, setContagemEncerrada] = React.useState<ContagemEncerrada>(estadoInicial)
  const [listaDeBusca, setListaDeBusca] = React.useState<ContagemEncerrada>(estadoInicial)
  const [ordenarPor, setOrdenarPor] = React.useState('descricaoEquipamento')
  const [equipamentoInformado, setEquipamentoInformado] = React.useState("")
  const [buscando, setBuscando] = React.useState(false)

  const opcoesOrdenacao = [
    { label: t("ordenacao.nome"), value: 'descricaoEquipamento', id: 1 },
    { label: t("ordenacao.grupo"), value: 'nomeGrupo', id: 2 },
    { label: t("ordenacao.quantidade"), value: 'quantidadeContada', id: 3 }
  ]

  React.useEffect(() => {
    const contagemEncerrada = async () => {
      apiContagemEncerrada(idContagem!, codigoLoja!)
        .then(response => {
          const { data } = response
          let itensComDivergencia = data?.itensPrevistos?.filter((equip: any) => equip.quantidadeContada !== equip.quantidadeNoSistema)

          if (!isEmpty(data?.items)) {
            setContagemEncerrada({ ...data, itensPrevistos: itensComDivergencia.sort(ordenarListaPorNome) })
            setListaDeBusca({ ...data, itensPrevistos: itensComDivergencia.sort(ordenarListaPorNome) })
          }
        })
        .catch(error => {
          notificaAirbrake(`ChamadaApiVisualizarContagemEncerrada: ${error}`, 'error', 'VisualizacaoContagem')
          setBuscando(false)
        })
    }
    contagemEncerrada()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buscarEquipamento = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event!.preventDefault();
    setBuscando(true)
    setEquipamentoInformado(event!.target.value.toLowerCase())
    let equipEncontrado: any = []

    if (equipamentoInformado !== '') {
      equipEncontrado = contagemEncerrada?.itensPrevistos.filter((equipamentos: any) => equipamentos.descricaoEquipamento.toLowerCase().includes(equipamentoInformado))
      setListaDeBusca({ ...listaDeBusca, itensPrevistos: equipEncontrado })
      await esperar(1500)
      setBuscando(false)
    } else if (equipamentoInformado.length < 2 || '') {
      setListaDeBusca({ ...listaDeBusca, itensPrevistos: contagemEncerrada?.itensPrevistos })
    }
  }

  const ordenarLista = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOrdenarPor(event.target.value as string)
    let opEscolhida = event.target.value as string
    let listaOrdenada: any = []
    const listaEquip = listaDeBusca?.itensPrevistos

    switch (opEscolhida || ordenarPor) {
      case "descricaoEquipamento":
        listaOrdenada = listaEquip?.sort(ordenarListaPorNome)
        setListaDeBusca({ ...listaDeBusca, itensPrevistos: listaOrdenada })
        break;
      case "nomeGrupo":
        listaOrdenada = listaEquip?.sort(ordenarListaPorNomeGrupo)
        setListaDeBusca({ ...listaDeBusca, itensPrevistos: listaOrdenada })
        break;
      case "quantidadeContada":
        listaOrdenada = listaEquip?.sort(ordenarListaPorQtd)
        setListaDeBusca({ ...listaDeBusca, itensPrevistos: listaOrdenada })
        break;
      default:
        listaOrdenada = listaEquip?.sort(ordenarListaPorNome)
        setListaDeBusca({ ...listaDeBusca, itensPrevistos: listaOrdenada })
    }
  }

  const limparCampoDeBusca = async () => {
    setEquipamentoInformado("")
    setBuscando(true)
    await esperar(1000)
    setListaDeBusca({ ...listaDeBusca, itensPrevistos: contagemEncerrada?.itensPrevistos })
    setBuscando(false)
  }

  const irParaDetalhesDivergencia = (equipamento: any) => {
    dispatch(setDivergencias(equipamento))
    history.push('/detalhes-divergencia')
  }

  return (
    <Box className="Container-Visualizar-Contagem">
      <Header />
      {contagemEncerrada.itensPrevistos.length === 0 ?
        <div className="Loader-Equipamentos">
          <CircularProgress
            size={60}
            variant="indeterminate"
            className="Progresso-Circular-Encerrada"
          />
        </div> :
        <>
          <div className="Dados-Contagem-Usuario">
            <Typography className="Data-Contagem-Encerrada">
              {contagemEncerrada?.dataInicio === '' ? '' : converterDataFusoLocal(contagemEncerrada?.dataInicio!)}
            </Typography>
            <Typography className="Data-Contagem-Encerrada">
              {contagemEncerrada?.dataFim === '' ? '' : converterDataFusoLocal(contagemEncerrada?.dataFim!)}
            </Typography>
            <Typography className="Nome-Usuario-Contagem-Encerrada">{contagemEncerrada?.funcionario?.nome}</Typography>
            <Typography className="Loja-Contagem-Encerrada">{`${contagemEncerrada?.loja?.nome} / ${contagemEncerrada?.loja?.codLoja}`}</Typography>
          </div>
          <div className="Campo-Busca-Equip-Encerrada">
            <TextField
              className="Campo-Busca-Encerrada"
              variant="standard"
              onChange={buscarEquipamento}
              value={equipamentoInformado}
              fullWidth
              placeholder={t("contagens.placeholder_equip")}
              InputProps={{
                endAdornment: (
                  <>
                    {equipamentoInformado === "" ?
                      <IconButton onClick={() => buscarEquipamento} disabled={equipamentoInformado === ""} className="Botoes-Input-Encerradas">
                        <SearchIcon />
                      </IconButton> :
                      <IconButton onClick={limparCampoDeBusca} className="Botoes-Input-Encerradas">
                        <ClearIcon />
                      </IconButton>
                    }
                  </>
                )
              }}
            />
          </div>
          <div className="Ordenacao-Encerradas">
            <Select
              value={ordenarPor}
              onChange={ordenarLista}
              className="Contagem-Encerrada-Filtro-Ordenacao"
            >
              {opcoesOrdenacao.map((op) => (
                <MenuItem key={op.id} value={op.value}>{op.label}</MenuItem>
              ))}
            </Select>
          </div>
          {buscando ?
            <div className="Loader-Equipamentos">
              <CircularProgress
                size={60}
                variant="indeterminate"
                className="Progresso-Circular-Encerrada"
              />
            </div> :
            <List disablePadding className="List-Root-Encerradas">
              {listaDeBusca?.itensPrevistos.length !== 0 && listaDeBusca?.itensPrevistos?.map((itens: any, index) => (
                <ListItem disableGutters divider key={index}>
                  <Card className="Card-Contagem-Encerrada">
                    <CardActionArea onClick={() => irParaDetalhesDivergencia(itens)}>
                      <CardContent className="Card-Content-Encerradas">
                        <div className="Area-Informativa-Encerrada">
                          <Typography className="Descricao-Equip-Divergente">{itens.descricaoEquipamento}</Typography>
                          <div className="Area-Cod-Grupo-Encerrada">
                            <Typography className="Cod-Equip-Divergente">{modificarCodigo(itens.codEquipamento)}</Typography>
                            <Typography className="Grupo-Equip-Divergente">{itens.nomeGrupo}</Typography>
                          </div>
                        </div>
                        <div className="Area-Botoes-Encerrada">
                          {itens.possuiNumeroSerie === true ?
                            <Checkbox
                              className="Checked-Disabled"
                              disabled={true}
                              value={itens.codEquipamento}
                              checked={itens.quantidadeContada === 1}
                              color="primary"
                            /> :
                            <div className="Button-Disabled">{itens.quantidadeContada}</div>
                          }
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </ListItem>
              ))}
            </List>
          }
          <Box className="Box-botao-enviar-email">
            <BotaoEnviarEmail />
          </Box>
        </>
      }
    </Box>
  )
}

export default VisualizacaoContagemEncerrada
