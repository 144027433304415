import React, { memo } from 'react';
import { useTranslation } from 'react-i18next'
import { Box, Tab, Tabs } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { tabCorrenteAtiva } from '../../redux/TabCorrente/action'
import { InterfaceTabCorrente } from '../../redux/TabCorrente';
import QrCode from '../QrCode';
import { EmAndamento, Header } from '../../components';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="Tab-Panel"
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function tabCorrenteAtual(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function ComponenteTiposContagemTabs() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { numeroTab } = useSelector((state: InterfaceTabCorrente) => state.tabCorrente)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    let tab = { numeroTab: newValue }
    dispatch(tabCorrenteAtiva(tab))
  }

  return (

    <Box className="Tab-Container">
      <div className="Tab-Subcontainer">
      <Header/>
        <Tabs
          value={numeroTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          centered
          variant="fullWidth"
        >
          <Tab label={t("contagem.qr_code")} {...tabCorrenteAtual(0)} />
          <Tab label={t("contagem.manual")} {...tabCorrenteAtual(1)} />
        </Tabs>
        <TabPanel value={numeroTab} index={0}>
            <QrCode />
        </TabPanel>
        <TabPanel value={numeroTab} index={1}>
          <EmAndamento/>
        </TabPanel>
      </div>
    </Box>
  )
}

export const Contagem = memo(ComponenteTiposContagemTabs)
