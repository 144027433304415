import React, { memo } from 'react'
import api from '../../../../infraestructure/api'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Dialog, Button, Typography, IconButton, CircularProgress } from '@material-ui/core'
import { SnackBar } from '../../..'
import CloseIcon from '@material-ui/icons/Close'
import './ButtonDescartar.scss'
import {notificaAirbrake} from '../../../../config/Airbrake'
import Transicao from '../../../ComponenteTransicao'

function ComponenteDescartarContagem(dadosContagem?: any) {

  const { t } = useTranslation()
  const history = useHistory()
  const [abrir, setAbrir] = React.useState(false)
  const [descartando, setDescartando] = React.useState(false)
  const [abrirSnack, setAbrirSnack] = React.useState({ open: false, vertical: 'bottom', horizontal: 'center' })
  const [mensagem, setMensagem] = React.useState("")

  const { vertical, horizontal } = abrirSnack

  const abrirModal = () => {
    if (window.navigator.onLine === false) {
      setMensagem(t("modal_conexao.finalizar"))
      setAbrirSnack({ ...abrirSnack, open: true })
    } else {
      setAbrir(true)
    }
  }

  const fecharModal = () => {
    setAbrir(false)
  }

  const descartarContagem = async () => {
    setDescartando(true)
    const { id, dataInicio, funcionario, itensPrevistos, loja, tipoContagem } = dadosContagem.dadosContagem
    const url = `/ccl-api-cadastros/ativos/contagem-cega/v1/contagens/${id}`
    let dataFim = new Date().toISOString()

    const dadosContagemAtualizado = {
      dataFim: dataFim?.split("Z")?.[0],
      dataInicio: dataInicio,
      functionario: funcionario,
      id: id,
      itensPrevistosNaContagem: itensPrevistos,
      loja: loja,
      statusContagem: "DESCARTADO",
      tipoContagem: tipoContagem
    }

    api.patch(url, dadosContagemAtualizado)
      .then(resposta => {
        const { status } = resposta
        if (status === 204) {
          history.push("/minhas-contagens")
        } else if (status === 422) {
          setDescartando(false)
        } else {
          setDescartando(false)
        }
      })
      .catch(error => {
        notificaAirbrake(`ChamadaApiDescartarContagem: ${error}`, 'erro', 'ModalDescartarContagem')
        setDescartando(false)
      })
  }

  const fecharSnack = () => {
    setAbrirSnack({ ...abrirSnack, open: false })
  }

  return (
    <div className="Container-Modal-Descarte">
      <Button className="Botao-Descartar" onClick={abrirModal}>{t("modal_descarte.botao_label")}</Button>
      <Dialog
        open={abrir}
        onClose={fecharModal}
        TransitionComponent={Transicao}
        className="Dialog-Descarte-Contagem"
      >
        <Button className="arrastar-fechar" onClick={fecharModal}></Button>
        <Typography className="Informativo">{t("modal_descarte.informativo")}</Typography>
        <Typography className="Alerta">{t("modal_descarte.alerta")}</Typography>
        <div className='div-botoes-contagem-andamento'>
          {descartando ?
            <CircularProgress
              size={50}
              variant="indeterminate"
              className="Progresso-Circular-Descarte-Contagem"
            />
            :
            <>
              <Button
                onClick={fecharModal}
                className="Manter-Contagem"
                disabled={descartando}
              >
                {t("modal_descarte.manter")}
              </Button>
              <Button
                onClick={descartarContagem}
                className="Descarte-Contagem"
                disabled={descartando}
              >
                {t("modal_descarte.descartar")}
              </Button>
            </>
          }
        </div>
      </Dialog>
      <SnackBar
        className="Snackbar-Nova"
        open={abrirSnack.open}
        mensagem={mensagem}
        onClose={fecharSnack}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        action={
          <IconButton
            color="inherit"
            onClick={fecharSnack}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  )
}

export const DescartarContagem = memo(ComponenteDescartarContagem)
