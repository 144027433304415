import React from 'react'
//Infraestrutura
import api from '../../infraestructure/api'
import { apiLojasDaRede } from '../../services/Lojas'
import * as Yup from 'yup'
import notificaAirbrake from '../../config/Airbrake'
//Bibliotecas
import { Form } from "@unform/web"
import { useTranslation } from "react-i18next"
//Componentes
import { Header } from '../'
import { Input, InputPassword } from "../UnformComponents"
import { Button, Typography, CircularProgress, Box, useMediaQuery } from "@material-ui/core"
import './Login.scss'
//Helpers
import { formatarTextoParaMaiusculo } from '../../helpers/helpers'

interface DadosLogin {
  idStore: string
  usuario: string
  senha: string
}

interface Error {
  error: string
  error_description: string
}

export const Login = () => {

  const { t } = useTranslation()
  const formRef = React.useRef<any | null>(null)
  const tamanhoDaTela = useMediaQuery(`(max-width: 1000px)`)
  let dataParaExp = new Date().setDate(new Date().getDate() + 1)

  const [enviandoDados, setEnviandoDados] = React.useState(false)
  const [dadosIniciais, setDadosIniciais] = React.useState<DadosLogin>({ idStore: '', usuario: '', senha: '' })

  const validacaoDeLogin = Yup.object().shape({
    idStore: Yup.string().min(3, t("validacao_login.numero_loja_valido")).required(t("validacao_login.numero_requirido")),
    usuario: Yup.string().required(t("validacao_login.usuario_requirido")),
    senha: Yup.string().min(3, t("validacao_login.senha_valida")).max(18).required(t("validacao_login.senha_requirida"))
  })

  const enviarDadosDeAcesso = async (dados: DadosLogin) => {
    setDadosIniciais(dados!)
    const { usuario, senha, idStore } = dados
    const url = "/ccl-authorization-server/oauth/autenticao-simples"

    const body = { "usuario": usuario?.toUpperCase(), "senha": senha }
    try {
      setEnviandoDados(true)
      formRef.current.setErrors({})
      await validacaoDeLogin.validate(dados, { abortEarly: false })

      api.post(url, body, {headers: {'id-store': idStore,'id-tenant': idStore}})
        .then((resposta) => {
          const { data } = resposta

          apiLojasDaRede(data.data.codLoja)
            .then(resposta => {
              let lojaAtual = resposta?.data?.filter((loja: any) => loja.numLoja === data.data.codLoja).map((loja: any) => ({ loja: `${loja.numLoja} - ${loja.loja}` }))

                localStorage.setItem('loja', lojaAtual[0].loja)
                localStorage.setItem("usuario-logado", "true")
                localStorage.setItem("usuario", data.data.nome)
                localStorage.setItem("id-funcionario", data.data.idFuncionario)
                localStorage.setItem("cod-loja", data.data.codLoja)
                localStorage.setItem("nome-loja", `${data.data.apelidoLoja}`)
                sessionStorage.setItem("data_exp", dataParaExp.toString())
                window.location.href = "/minhas-contagens"
            })
        })
        .catch(error => {
          setEnviandoDados(false)
          const {status, data} = error.response
          if (status === 401) {
            if (data.error_description === "Informe um n&uacute;mero de loja v&aacute;lido") {
              formRef.current.setFieldError("idStore", t("validacao_login.numero_loja_valido"))
            }
            if (data.error_description === "Informe um usu&aacute;rio v&aacute;lido") {
              formRef.current.setFieldError("usuario", t("validacao_login.usuario_requirido"))
            }
            if (data.error_description === "Informe uma senha v&aacute;lida") {
              formRef.current.setFieldError("senha", t("validacao_login.senha_backend"))
            }
            if (data.error_description === "Sua senha expirou. Por favor, altere sua senha pelo CCL e tente fazer o login novamente.") {
              formRef.current.setFieldError("senha", t("validacao_login.senha_expirada"))
            }
          }
          notificaAirbrake(`ChamadaApiLogin: ${data.error_description}`, 'erro', 'TelaLogin')
        })

    } catch (error: Error | any) {
      setEnviandoDados(false)
      if (error instanceof Yup.ValidationError) {
        const message: any = {}

        error.inner.forEach(err => {
          message[err.path!] = err.message
        })

        formRef.current.setErrors(message)
      }
      setEnviandoDados(false)
    }
  }

  return (
    <Box className="box-container-login">
      <Header />
      {enviandoDados ?
        <CircularProgress
          size={50}
          variant="indeterminate"
          className="Progresso-Circular-Login"
        />
        :
        <Box className="Box-Content-Login" width={tamanhoDaTela ? '90%' : '40%'}>
          <Typography className="Titulo-Login">
            {t("login.titulo")}
          </Typography>
          <Form onSubmit={enviarDadosDeAcesso} ref={formRef} className="Form-Login">
            <Input
              name="idStore"
              label={t("login.numero")}
              variant="outlined"
              type="text"
              onChange={(event) => formatarTextoParaMaiusculo(event)}
              defaultValue={dadosIniciais.idStore}
            />
            <Input
              name="usuario"
              label={t("login.nome")}
              variant="outlined"
              type="text"
              onChange={(event) => formatarTextoParaMaiusculo(event)}
              defaultValue={dadosIniciais.usuario}
            />
            <InputPassword
              name="senha"
              label={t("login.senha")}
            />
            <Button
              className="Button"
              type="submit"
              variant="contained"
              color="primary"
              disabled={enviandoDados}
            >
              {t("login.confirmar")}
            </Button>
          </Form>
        </Box>
      }
    </Box>
  )
}

export default Login;
