import { Reducer } from 'redux'
import { codLoja } from '../../infraestructure/auth'
import { FiltroContagens } from './actionTypes'

export interface Filtro {
  filtros: {
    inicioPeriodoContagem: string
    fimPeriodoContagem: string
    grupoLoja: string
    tipoContagem: string
    idFuncionario?: string
    grupoEquipamentos: string
  }
}

const estadoInicial: any = {
  inicioPeriodoContagem: "",
  fimPeriodoContagem: "",
  grupoLoja: codLoja()!,
  tipoContagem: "",
  idFuncionario: "",
  grupoEquipamentos: ""
}

const BuscarContagensFiltradas: Reducer<Filtro> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case FiltroContagens.BUSCAR_CONTAGENS_FILTRADAS: {
      return action.payload
    }
    default:
      return state
  }
}

export default BuscarContagensFiltradas
