import React from 'react'
//Services
import { apiOutrasContagensAtivas } from '../../../services/OutrasContagens'
import { useDispatch } from 'react-redux'
import { buscarOutrasContagensAtivas } from '../../../redux/OutrasContagens/action'
import { converterData } from '../../../helpers/helpers'
import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { notificaAirbrake } from '../../../config/Airbrake'
//Componentes
import { Typography, ListItem, List, Card, CardContent } from '@material-ui/core'
import { CardCarregamento } from '../../CardCarregamento'
import InfiniteScroll from "react-infinite-scroller"
import './OutrasContagens.scss'

export function OutrasContagens() {

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [listarOutrasContagens, setListarOutrasContagens] = React.useState<any>([])
  const [temMaisContagens, setTemMaisContagens] = React.useState(true)
  const [paginaAtual, setPaginaAtual] = React.useState(0)

  let totalPages = listarOutrasContagens?.totalPages

  const carregarOutrasContagens = async () => {
    apiOutrasContagensAtivas(paginaAtual.toString(), "10")
      .then(resposta => {
        const { data } = resposta
        setPaginaAtual(paginaAtual + 1)
        if (data?.totalItems <= 10) {
          setTemMaisContagens(false)
        }

        dispatch(buscarOutrasContagensAtivas(data))
        setListarOutrasContagens(data)
      })
      .catch(error => {
        notificaAirbrake(`ChamadaApiOutrasContagens: ${error}`, 'erro', 'OutrasContagens')
        setTemMaisContagens(false)
      })
  }

  React.useEffect(() => {
    carregarOutrasContagens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const carregarMaisContagens = async () => {
    if (paginaAtual <= totalPages) {
      setTemMaisContagens(false)
      setPaginaAtual(paginaAtual + 1)

      apiOutrasContagensAtivas(paginaAtual.toString(), "10")
        .then(resposta => {
          const { data } = resposta
          if (!isEmpty(data?.items)) {
            let maisPagina = paginaAtual <= totalPages
            setTemMaisContagens(maisPagina)
            data.items.forEach((itens: any) => { listarOutrasContagens?.items?.push(itens) })
          }
        })
        .catch(error => {
          notificaAirbrake(`ChamadaApiOutrasContagens: ${error}`, 'erro', 'OutrasContagens, CarregarMaisContagens')
          setTemMaisContagens(false)
        })
    }
  }

  return (
    <div className="Scroll-Infinita-Outras">
      <InfiniteScroll
        initialLoad={false}
        loadMore={carregarMaisContagens}
        loader={
          <div
            key={0}
          >
            <CardCarregamento active={false} />
          </div>
        }
        hasMore={temMaisContagens}
        useWindow={false}
      >
        <List component="div" disablePadding className="List-Container-Outras">
          {listarOutrasContagens?.items?.[0] === undefined && totalPages === 0 ?
            <Typography className="Sem-Contagns-Ativas">Nenhuma contagem ativa no momento!</Typography>
            :
            listarOutrasContagens?.items !== undefined && listarOutrasContagens.items.map((dados: any) => (
              <ListItem disableGutters divider className="List-Card-Outras-Contagens" key={dados?.id}>
                <Card className="Cards-Outras-Contagens">
                  <CardContent className="Card-Content-Outras-Contagens">
                    <Typography className="Data-Outras-Contagens">{converterData(dados?.dataInicio)}</Typography>
                    <Typography className="Nome-Codigo-Loja-Outras">
                      {`${dados?.nomeLoja} / ${dados.codLoja}`}
                    </Typography>
                    <div className="linha-usuario-seta">
                      <Typography className="Nome-Usuario-Outras">
                        {dados?.nomeFuncionario?.toLowerCase().replace(/(?:^|\s)\S/g, function (text: any) {
                          return text?.toUpperCase();
                        })}
                      </Typography>
                    </div>
                    <Typography className="Tipo-Contagem-Outras">
                      {dados?.tipoContagem === "GERAL" ? t("contagens.geral") : t("contagens.personalizada")}
                    </Typography>
                  </CardContent>
                </Card>
              </ListItem>
            ))
          }
        </List>
      </InfiniteScroll>
    </div>
  )
}

export default OutrasContagens
