import React from 'react'
//Serviços
import { apiContagemAtiva } from '../../../services/MinhasContagens'
import { buscarMinhasContagensAtivas } from '../../../redux/MinhasContagens/ativas/action'
import { InterfaceAbrirEncerrada, abrirEncerrada } from '../../../redux/AbrirEncerradas'
import { MinhasContagensAtivas } from '../../../redux/MinhasContagens/ativas'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { notificaAirbrake } from '../../../config/Airbrake'
//Estilos
import { Collapse, ListItemText, ListItem, List } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Encerradas } from './encerradas/encerradas'
import { Ativas } from './ativas/ativas'
import "./MinhasContagens.scss"

export function MinhasContagens() {

  const {t} = useTranslation()
  const [abrirAtivas, mostrarAtivas] = React.useState(true)
  const { setAbrirEncerrada } = useSelector((state: InterfaceAbrirEncerrada) => state.abrirEncerradas)

  const dispatch = useDispatch()
  const { contagemAtiva } = useSelector((state: MinhasContagensAtivas) => state)

  const cliqueMostrarAtivas = () => {
    mostrarAtivas(!abrirAtivas)
  }

  const cliqueMostrarEncerradas = () => {
    dispatch(abrirEncerrada({setAbrirEncerrada: !setAbrirEncerrada}))
  }

  React.useEffect(() => {
    const carregarContagemAtiva = async () => {
      apiContagemAtiva()
        .then(resposta => {
          const { data } = resposta
          dispatch(buscarMinhasContagensAtivas(data))
        })
        .catch(error => {
          console.log(error)
          notificaAirbrake(`ChamadaApiContagemAtiva: ${error}`, 'erro', 'MinhasContagensAtivas')
        })
    }
    carregarContagemAtiva()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <List className="list-container-ativas">
        <ListItem button onClick={cliqueMostrarAtivas} disableGutters dense>
          <div className="Botao-Expandir">
            <ListItemText primary={t("contagens.ativas")} className="Contagem-Ativas" />
            {abrirAtivas ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </div>
        </ListItem>
        <Collapse in={abrirAtivas} >
          <Ativas contagemAtiva={contagemAtiva}/>
        </Collapse>
      </List>
      {/* Encerradas */}
      <List className="list-container-encerradas">
        <ListItem button onClick={cliqueMostrarEncerradas} disableGutters dense>
          <div className="Botao-Expandir-Encerradas">
            <ListItemText primary={t("contagens.encerradas")} className="Contagem-Encerradas" />
            {setAbrirEncerrada ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </div>
        </ListItem>
        <Collapse in={setAbrirEncerrada} >
          <Encerradas/>
        </Collapse>
      </List>
    </>
  )
}

export default MinhasContagens
