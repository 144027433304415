import React from 'react'
import api from '../../../../infraestructure/api'
import Transicao from '../../../ComponenteTransicao'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import IconeConclusao from '../../../../assets/IconeConclusao'
import { Button, Dialog, Typography, TextField, Box, CircularProgress, IconButton } from '@material-ui/core'
import './BotaoEnviarEmail.scss'
import { contagemId } from '../../../../infraestructure/auth'
import notificaAirbrake from '../../../../config/Airbrake'

export function BotaoEnviarEmail() {

  const { t } = useTranslation()

  const [abrir, setAbrir] = React.useState(false)
  const [enviando, setEnviando] = React.useState(false)
  const [carregando, setCarregando] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [mensagemErro, setMensagemErro] = React.useState('')

  const confirmarEmail = () => {
    const urlEmail = `ccl-api-cadastros/ativos/contagem-cega/v1/contagens/${contagemId()}/envios?email=${email}`

    if(/\S+@\S+\.\S+/.test(email)) {
      setEnviando(true)
      setCarregando(true)
      setError(false)

      api.post(urlEmail)
        .then(() => {
          setError(false)
          setMensagemErro('')
          setEnviando(false)
          setCarregando(false)
          setAbrir(false)
        })
        .catch(error => {
          const { errors } = error.response.data
          notificaAirbrake(
            "ChamadaApiEnviarEmail",
            "erro",
            `Erro ao enviar contagem por email: ${JSON.stringify(errors)}, contagemId: ${contagemId()}, email: ${email}`
          )
          setEnviando(false)
          setCarregando(false)
          setError(true)
          setMensagemErro(errors?.[0]?.mensagemUsuario)
        })
    } else {
      setError(true)
      setMensagemErro(t("modal_email.msg_erro"))
    }
  }

  const fechar = () => {
    setAbrir(false)
    setError(false)
    setMensagemErro('')
    setCarregando(false)
    setEnviando(false)
  }

  const capturaEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.preventDefault()
    setError(false)
    setMensagemErro('')
    setEmail(event.target.value)
  }

  return (
    <>
      <Button className="btn-enviar-email" onClick={() => setAbrir(true)}>{t("modal_email.btn_modal")}</Button>
      <Dialog
        open={abrir}
        onClose={fechar}
        TransitionComponent={Transicao}
        className="dialog-enviar-email"
      >
        <Button className="botao-arrastar-fechar-email" onClick={fechar}></Button>
        {enviando ?
        <>
          {carregando ?
            <CircularProgress
              size={75}
              variant="indeterminate"
              className="circular-progress-dialog"
            /> :
            <>
              <IconButton className="icone-fechar-email" onClick={fechar}>
                <HighlightOffIcon/>
              </IconButton>
              <IconeConclusao/>
              <Typography className="texto-email-enviado">{t("modal_email.msg_sucesso")}</Typography>
            </>
          }
        </> :
        <>
          <Typography className="Titulo-dialog-email">{t("modal_email.titulo")}</Typography>
          <TextField
            variant='outlined'
            size='medium'
            className="input-email"
            label={t("modal_email.label")}
            type='email'
            fullWidth
            onChange={(event) => capturaEmail(event)}
            error={error}
            helperText={mensagemErro}
          />
          <Box className="Box-botoes-email">
            <Button className="btn-confirmar" onClick={confirmarEmail}>
              Confirmar
            </Button>
            <Button className="btn-cancelar" onClick={fechar}>
              Cancelar
            </Button>
          </Box>
        </>
        }
      </Dialog>
    </>
  )
}

export default BotaoEnviarEmail
