import { Notifier } from '@airbrake/browser';

const airbrake = new Notifier({
  projectId: 387183,
  projectKey: '69caa243c87d3c6d17f24642722c7cec',
  instrumentation: { onerror: false }
})

export const notificaAirbrake = (error = '', severity = 'error', funcao = '', params = {}) => {
  if (process.env.REACT_APP_AMBIENTE === 'production') {
    airbrake.addFilter((filters) => {
      const filtros = filters

      if (funcao !== '' && filtros.errors) {
        filtros.errors[0].backtrace[0].function = funcao
      }
      filtros.context.environment = 'production'

      return filtros
    })

    airbrake.notify({
      error,
      context: { severity },
      params,
    })
  }
}

export default notificaAirbrake;
