import React from 'react'
import { Grid } from '@material-ui/core'
import './Contagens.scss'
import { TabsContagens } from '../../components';

export function Contagens() {

  return (
    <Grid
      container
      className="Contagens-container"
    >
      <Grid item className="Contagens-items">
        <TabsContagens />
      </Grid>
    </Grid>
  )
}

export default Contagens;
