import React from 'react';
import notificaAirbrake from '../config/Airbrake';

export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error: any, info: any) {
    notificaAirbrake(error, 'critical', '', info);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
