import { Reducer } from 'redux'
import { EmAndamento } from './actionTypes'

interface Itens {
  codEquipamento: string
  codGrupo: string
  codSubgrupo: string
  descricaoEquipamento: string
  possuiNumeroSerie: boolean | undefined
  nomeGrupo: string
  nomeSubgrupo: string
  quantidadeAfastada: number
  quantidadeAguardandoDevolucao: number
  quantidadeComprometida: number
  quantidadeContada: number
  quantidadeDisponivel: number
  quantidadeNoSistema: number
  quantidadeReservada: number
  quantidadeSubstituiu: number
}

export interface Equipamentos {
  contagemEmAndamento: {
    dataFim: string,
    dataInicio: string,
    funcionario: {id: number, nome: string},
    id: string,
    itensPrevistos: Array<Itens>,
    loja: {codGrupo: string, codLoja: string, nome: string},
    statusContagem: string,
    tipoContagem: string,
  }
}

const estadoInicial: Equipamentos = {
  contagemEmAndamento: {
    dataFim: '',
    dataInicio: '',
    funcionario: {id: 0, nome: ''},
    id: '',
    itensPrevistos: [],
    loja: {codGrupo: '', codLoja: '', nome: ''},
    statusContagem: '',
    tipoContagem: '',
  }
}

const BuscarContagemEmAndamento: Reducer<Equipamentos> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case EmAndamento.CONTAGEM_EM_ANDAMENTO: {
      return action.payload
    }
    default:
      return state
  }
}

export default BuscarContagemEmAndamento
