import React from 'react'
import { Snackbar } from '@mui/material'

interface Props {
  open: boolean;
  autoHideDuration?: number | null
  mensagem?: string
  action?: any
  onClose?: () => void
  className?: string
  anchorOrigin?: any
}

export function SnackBar({open, autoHideDuration, mensagem, action, onClose, className, anchorOrigin, ...rest}: Props) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      message={mensagem}
      action={action}
      className={className}
      onClose={onClose}
      {...rest}
      anchorOrigin={anchorOrigin}
    />
  )
}

export default SnackBar
