import React, {memo} from 'react'
//Servicos
import { MinhasContagensAtivas } from '../../../redux/MinhasContagens/ativas'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router'
//Componentes e estilos
import { Transicao } from '../../ComponenteTransicao'
import { Button, Dialog, DialogActions, DialogContent, Typography, IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { FormularioNovaContagem } from './formularioNovaContagem'
import './BotaoNovaContagem.scss'

function ComponenteBotaoNovaContagem() {

  const { t } = useTranslation()
  const history = useHistory()
  const { contagemAtiva } = useSelector((state: MinhasContagensAtivas) => state)
  const [open, setOpen] = React.useState(false)

  const abrirFormulario = () => {
    setOpen(true)
  }

  const fecharFormulario = () => {
    setOpen(false)
  }

  const retornarContagem = () => {
    history.push("/contagem-em-andamento")
  }

  return (
    <div className="Container-Botao-Nova-Retornar">
      {contagemAtiva.id === undefined ?
        <>
          <Button className="Botao-Nova-Retornar" onClick={abrirFormulario}>
            {t("contagens.nova")}
          </Button>
          <Dialog
            open={open}
            TransitionComponent={Transicao}
            keepMounted
            onClose={fecharFormulario}
            className="Container-Dialog"
          >
            <DialogActions className="Dialog-Actions">
              <Button className="Botao-Arrastar" onClick={fecharFormulario}></Button>
            </DialogActions>
            <DialogContent className="Dialog-Content">
              <div className="Dialog-Grid-Container-Nova">
                <div className="Dialog-Grid-Titulo-Botao">
                  <Typography className="Dialog-Form-Titulo">{t("nova_contagem.nova")}</Typography>
                  <IconButton className="Dialog-Form-Botao-Fechar" onClick={fecharFormulario}>
                    <HighlightOffIcon />
                  </IconButton>
                </div>
                <div className="Dialog-Grid-Form-Nova">
                  <FormularioNovaContagem/>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </>
        :
        <Button className="Botao-Nova-Retornar" onClick={retornarContagem}>
          {t("contagens.retomar")}
        </Button>
      }
    </div>
  )
}

export const BotaoNovaContagem = memo(ComponenteBotaoNovaContagem)
