import React from 'react'

export const IconeConclusao = () => {
  return (
    <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg" className='icone-conclusao'>
      <circle cx="40.5" cy="40.5" r="40.5" fill="#041D5D" />
      <path d="M31.7164 52.6418L19.1791 40.1045L15 44.2836L31.7164 61L67.5373 25.1791L63.3582 21L31.7164 52.6418Z" fill="white" />
    </svg>
  )
}

export default IconeConclusao
