import { AxiosPromise, AxiosRequestConfig } from 'axios';
import api from '../../infraestructure/api'
import { codLoja } from '../../infraestructure/auth';

interface Error {
  message: Array<object>
}

export const buscarGrupoEquipamentos = (cod_loja?: string, descricao?: string, page?: string, size?: string, sort?: string):AxiosPromise<Object> => {
  const url = `ccl-api-equipment-stock/v1/equipment-stock/grupos`
  const urlEquipamentos = `${url}?nome=${descricao}&page=${page}&size=${size}&sort=${sort}`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: urlEquipamentos,
    headers: {
      'id-store': cod_loja,
      'id-tenant': cod_loja
    }
  }

  return api(parametros);
}

export const buscarGrupoEquipamentosSemDescricao = (cod_loja?: string, page?: string, size?: string, sort?: string):AxiosPromise<Object> => {
  const url = `ccl-api-equipment-stock/v1/equipment-stock/grupos`
  const urlEquipamentos = `${url}?page=${page}&size=${size}&sort=${sort}`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: urlEquipamentos,
    headers: {
      'id-store': cod_loja,
      'id-tenant': cod_loja
    }
  }

  return api(parametros);
}

export const buscarNovosEquipamentos = (descricao?: string, page?: string, size?: string,sort?: string):AxiosPromise<Object> => {
  const url = `ccl-api-equipment-stock/v1/equipment-stock/equipamentos?`
  const urlNovosEquip = `${url}descricao=${descricao}&page=${page}&size=${size}&sort=${sort}`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: urlNovosEquip,
    headers: {
      'id-store': codLoja(),
      'id-tenant': codLoja()
    }
  }

  return api(parametros);
}

export const apiBuscarGrupoEquipamentos = async (cod_loja?: string, descricao?: string, page?: string, size?: string, sort?: string) => {
  try {
    const resposta = await buscarGrupoEquipamentos(cod_loja, descricao, page, size, sort);

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export const apiBuscarGrupoEquipamentosSemDescricao = async (cod_loja?: string, page?: string, size?: string, sort?: string) => {
  try {
    const resposta = await buscarGrupoEquipamentosSemDescricao(cod_loja, page, size, sort);

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export const apiBuscarNovosEquipamentos = async (descricao?: string, page?: string, size?: string,sort?: string) => {
  try {
    const resposta = await buscarNovosEquipamentos(descricao, page, '10', 'asc')

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export default {}
