import React, { useEffect } from 'react';
import { DialogTitle, Grid } from '@material-ui/core';

import './QrCode.scss';
import { useDispatch, useSelector } from 'react-redux';
import { QrCodeItem } from './products';
import { adicionarProduto, atualizaLista } from '../../redux/QrCode/action';
import QrCodeReader from './qr-code-reader';
import { getEquipWithQrCode } from '../../services/QrCode/qr-code.repository';
import { DescartarContagem } from '../../components/Contagens/ContagemEmAndamento/ButtonDescartarContagem';
import { FinalizarContagem } from '../../components/Contagens/ContagemEmAndamento/ButtonFinalizarContagem';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import Transicao from '../../components/ComponenteTransicao';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { apiContagemAtiva } from '../../services/MinhasContagens';


export function  QrCode() {

  const [loading, setLoading] = React.useState(true)
  const [usingCamera, setUsingCamera] = React.useState(false)
  const [qrCode, setQrCode] = React.useState("")
  const [dialog, setOpenDialog] = React.useState(false)
  const [equipo, setEquipo] = React.useState<QrCodeItem | any>({})
  const dispatch = useDispatch()

  const localEquipamentos = useSelector(({ qrCodeState }: any) => qrCodeState)

  const { t } = useTranslation()

  const resetQrCode = () => {
    setQrCode("")
  }

  const closeDialog = () => {
    setOpenDialog(false)
    resetQrCode()
  }

  const saveEquipo = () => {
    dispatch(adicionarProduto(qrCode))
    closeDialog()    
    resetQrCode()
  }

  const openDialog = () => {
    setOpenDialog(true)
  }

  useEffect(() => {
    checkItem(qrCode)
  },[qrCode]) 

  const checkItem = (qrCode: any) => {
    if(qrCode){
      const item = localEquipamentos?.itensPrevistos
      ?.find((item: QrCodeItem) => item.qrCodeId === qrCode)
      if(item){
        setEquipo(item)
        openDialog()
      }
    }
  }

  const onCodeRead = (result: any, _: any) => {
    if (result?.text) {
      setQrCode(result?.text)
    }
  }

  const onReady = () => {
    setUsingCamera(true)
    setLoading(false)
  }

  useEffect(() => {
    const getEquipos = async () => {
      apiContagemAtiva().then(async (response) => {
        const { id, codLoja } = response.data
        if(id){
          const { data } = await getEquipWithQrCode(id, codLoja)
          dispatch(atualizaLista(data))
          onReady()
        }
      })
    }

    const itemsSize = localEquipamentos?.itensPrevistos?.length ?? 0
    if(itemsSize === 0){
      getEquipos()
    } else {
      onReady()
    }
  }, [])



  return (
    <>
    { loading ? <div className="Progresso-Circular">
      <CircularProgress
        size={50}
        variant="indeterminate"/>
    </div> : (

<Grid container className="QrCode">
      <Grid item className="QrCode-Grid-Item">
          {
            usingCamera && !dialog ?
              (<QrCodeReader
                canRead={usingCamera}
                onCodeRead={onCodeRead}
              ></QrCodeReader>)
              :
              (<>
          <Dialog
            open={dialog}
            TransitionComponent={Transicao}
            keepMounted
            onClose={closeDialog}
            className="Container-Dialog">
            <DialogTitle>{equipo.descricaoEquipamento}</DialogTitle>
            <DialogContent className="Dialog-Content">
                  {equipo.codEquipamento}                
            </DialogContent>
              <DialogActions>
                <Button className="Botao-Cancelar" onClick={closeDialog}>{t('qr_code.cancelar')}</Button>
                <Button className="Botao-Finalizar" onClick={saveEquipo}>{t('qr_code.confirmar')}</Button>
            </DialogActions>
          </Dialog>
              
              </>)}
      </Grid>
      {/* Adicionar parametros ao descartar contagem e finalizar contagem */}

      <div className="Opcoes-Descarte-Finalizar">
        <DescartarContagem dadosContagem={localEquipamentos}/>
        <FinalizarContagem dadosContagem={localEquipamentos}/>
      </div>
      
    </Grid>
      )}    
    </>
  )
}


export default QrCode;

