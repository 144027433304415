import React from 'react';
import { QrReader } from 'react-qr-reader';
import './qrCodeReader.scss'

export function QrCodeReader(props: any) {
    return (
        <QrReader
        containerStyle= {
            {
                display: "flex",
                marginTop: "50px",
            }
        }
        className='QrCodeReader'
            onResult={(result, error) => {
                if(props.canRead) props.onCodeRead(result, error)
            }} 
            constraints={{ facingMode: 'environment' }}
         />
    )
}


export default QrCodeReader;
