export function formatadorDeDatas(date: string) {

  let formatDate

  if (date?.includes('-')) {
    formatDate = date?.split('-').reverse().join('/')
  } else {
    formatDate = date?.split('/').reverse().join('/')
  }

  return formatDate
}

export function converterData(data: string) {
  let dataConvertida = data?.split("T")
  let reverterData = formatadorDeDatas(dataConvertida?.[0])

  return reverterData
}

export function retornarDataEHora(date: string) {
  const horas = date?.split("T")?.[1]?.split(".")?.[0]
  const data = formatadorDeDatas(date?.split("T")?.[0])
  const dataEHora = `${data} - ${horas}`

  return dataEHora
}

export function contadorDePaginas({items, pageActual, limitItems}: any) {
  let result = [];
  let totalPage = Math.ceil(items.length / limitItems);
  let count = (pageActual * limitItems) - limitItems;
  let delimiter = count + limitItems;

  if (pageActual <= totalPage) {
    for (let i = count; i < delimiter; i++) {
      if (items[i] != null) {
        result.push(items[i]);
      }
      count++;
    }
  }
  return result;
}

export function formatarTextoParaMaiusculo(texto: React.ChangeEvent<HTMLInputElement>) {
  if(texto.target.value.length >= 3) {
    let inicioTexto = texto.target.selectionStart;
    let fimTexto = texto.target.selectionEnd;
    texto.target.value = texto.target.value.toUpperCase();
    texto.target.selectionStart = inicioTexto;
    texto.target.selectionEnd = fimTexto;

    return texto.target.value;
  }
}

export const modificarCodigo = (codigo: string) => {
  var novoCod = codigo?.split(".")
  var separacao = `${novoCod?.[0]}.${novoCod?.[1]}.${novoCod?.[2]}.${novoCod?.[5]}`
  var removeTraco = separacao?.split("-")
  var novoCod2 = separacao?.split("-")?.[0]?.split(".")
  var codFinal = `${novoCod2?.[0]}.${novoCod2?.[1]}.${novoCod2?.[2]}-${removeTraco?.[1]}`

  return codFinal
}
export const esperar = (ms: number) =>
  new Promise<void>(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export function ordenarListaPorNome(a: any, b:any) {
  if (a.descricaoEquipamento < b.descricaoEquipamento)
     return -1;
  if (a.descricaoEquipamento > b.descricaoEquipamento)
    return 1;
  return 0;
}

export function ordenarListaPorNomeGrupo(a: any, b:any) {
  if (a.nomeGrupo < b.nomeGrupo)
     return -1;
  if (a.nomeGrupo > b.nomeGrupo)
    return 1;
  return 0;
}

export function ordenarListaPorQtd(a: any, b:any) {
  if (a.quantidadeContada > b.quantidadeContada)
     return -1;
  if (a.quantidadeContada < b.quantidadeContada)
    return 1;
  return 0;
}

export function converterDataFusoLocal(data: string) {
  let dataConvertida = new Date(data).toLocaleString()
  let dataFormatada = dataConvertida.split(" ").join(" - ")

  return dataFormatada
}

export function primeiraMaiuscula(texto: any) {
  var textoMinusculo = texto.toLowerCase();
  var novoTexto = textoMinusculo.split(" ");
  for (var a = 0; a < novoTexto.length; a++) {
    var l = novoTexto[a];

    var primeiraLetra = l[0];
    l = primeiraLetra.toUpperCase() + l.slice(1);

    novoTexto[a] = l;
  }
  return novoTexto.join(" ");
}
