import React from 'react';
import { useTranslation } from 'react-i18next'
import { sair } from '../../../infraestructure/auth'
import { Button, Grid, CircularProgress } from '@material-ui/core';
import './Rodape.scss'

export const Rodape: React.FC = () => {

  const [finalizandoSessao, setFinalizandoSessao] = React.useState(false)
  const {t} = useTranslation()

  const logout = () => {
    setFinalizandoSessao(true)
    sair()
  }

  return (
    <Grid
      container
      className="Rodape-Container"
    >
      {finalizandoSessao ?
        <CircularProgress
          size={30}
          variant="indeterminate"
          className="Finalizando-Sessao"
        />
        :
        <Button variant="contained" onClick={logout}>
          {t("menu.botao_sair")}
        </Button>
      }
    </Grid>
  );
}

export default Rodape;
