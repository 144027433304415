import { Reducer } from 'redux'
import { DivergenciaActionTypes } from './actionTypes'

export interface IDivergencia {
  divergencia: {
    codEquipamento: string
    codGrupo: string
    codSubgrupo: string
    descricaoEquipamento: string
    nomeGrupo: string
    nomeSubgrupo: string
    observacao: string | null
    possuiNumeroSerie: boolean
    quantidadeAfastada: number
    quantidadeAguardandoDevolucao: number
    quantidadeComprometida: number
    quantidadeContada: number
    quantidadeDisponivel: number
    quantidadeNoSistema: number
    quantidadeReservada: number
    quantidadeSubstituiu: number
  }
}

const estadoInicial: IDivergencia = {
  divergencia: {
    codEquipamento: '',
    codGrupo: '',
    codSubgrupo: '',
    descricaoEquipamento: '',
    nomeGrupo: '',
    nomeSubgrupo: '',
    observacao: null,
    possuiNumeroSerie: false,
    quantidadeAfastada: 0,
    quantidadeAguardandoDevolucao: 0,
    quantidadeComprometida: 0,
    quantidadeContada: 0,
    quantidadeDisponivel: 0,
    quantidadeNoSistema: 0,
    quantidadeReservada: 0,
    quantidadeSubstituiu: 0,
  }
}

const ReducerDivergencias: Reducer<IDivergencia> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case DivergenciaActionTypes.SET_DIVERGENCIAS: {
      return action.payload
    }
    case DivergenciaActionTypes.UPDATE_OBSERVACAO: {
      return {
        ...state,
        observacao: action.payload
      }
    }
    default:
      return state
  }
}

export default ReducerDivergencias
