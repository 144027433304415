import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import LogoCasa from '../../../assets/logoCasa.svg';
import './Cabecalho.scss'
import { usuarioLogado, lojaAtual, codLoja } from '../../../infraestructure/auth';

interface DadosLoja {
  loja: string | null,
  codigo: string | null
}

export const Cabecalho: React.FC = () => {

  const nomeUsuarioLogado = usuarioLogado()
  const lojaCorrespondente = lojaAtual()
  const codigoLojaAtual = codLoja()

  const [usuario, setUsuario] = useState<string | null>(null);
  const [dadosLoja, setDadosLoja] = useState<DadosLoja>({loja: null,  codigo: null})

  useEffect(() => {
    setUsuario(nomeUsuarioLogado);
    setDadosLoja({loja: lojaCorrespondente, codigo: codigoLojaAtual})
  }, [nomeUsuarioLogado, lojaCorrespondente, codigoLojaAtual]);

  return (
    <Grid container className="Container" alignItems="flex-start">
      <Grid item xs={12}>
        <img
          alt="Logo Casa do Construtor"
          src={LogoCasa}
          className="Icone"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className="NomeLogado">
          {usuario}
        </Typography>
        <Typography className="NomeLoja">
          {`${dadosLoja.loja} / ${dadosLoja.codigo}`}
        </Typography>
      </Grid>
    </Grid>

  );
}

export default Cabecalho;
