import React from 'react'
//Services
import { apiLojasDaRede } from '../../services/Lojas'
import { aplicarFiltrosNasListas } from '../../redux/Filtro/action'
import { atualizaPaginaEncerradas, atualizaPaginaHistorico } from '../../redux/Paginas/action'
import { apiBuscarGrupoEquipamentos, apiBuscarGrupoEquipamentosSemDescricao } from '../../services/Equipamentos'
import { buscarHistoricoDeContagens } from '../../redux/HistoricoContagens/action'
import { buscarMinhasContagensEncerradas } from '../../redux/MinhasContagens/encerradas/action'
import { InterfaceTabCorrente } from '../../redux/TabCorrente'
import { abrirEncerrada, InterfaceAbrirEncerrada } from '../../redux/AbrirEncerradas'
//Helpers
import { useDispatch, useSelector } from 'react-redux'
import { esperar, converterData } from '../../helpers/helpers'
import { codLoja, lojaPrincipal } from '../../infraestructure/auth'
import { isEmpty } from 'ramda'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
//Components
import { Form } from '@unform/web'
import { ChipFlutuante, MaterialDatePicker, SnackBar } from '..'
import { HighlightOff, StoreOutlined, Close } from '@material-ui/icons'
import { MultipleSelect, Select } from '../UnformComponents'
import { Button, Drawer, IconButton, Typography, CircularProgress } from '@material-ui/core'
import './Filtro.scss'
import notificaAirbrake from '../../config/Airbrake'
import { IconeFiltro } from '../../assets/IconeFiltro'

interface ChipData {
  key: number;
  label: string;
  value: string;
}

interface Lojas {
  label: string;
  value: string;
}

export function Filtro() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const formRef = React.useRef<any | null>(null)

  const { numeroTab } = useSelector((state: InterfaceTabCorrente) => state.tabCorrente)
  const { setAbrirEncerrada } = useSelector((state: InterfaceAbrirEncerrada) => state.abrirEncerradas)
  const [opcao, setOpcao] = React.useState({ tipoContagem: "GERAL" })
  const [lojasDaRede, setLojasDaRede] = React.useState<Lojas | any>([])
  const [abrirFiltro, setAbrirFiltro] = React.useState(false)
  const [aplicandoFiltro, setAplicandoFiltro] = React.useState(false)
  const [tab, setTab] = React.useState(0)
  const [dataInicio, setDataInicio] = React.useState(null)
  const [dataFim, setDataFim] = React.useState(null)
  const [erro, setErro] = React.useState("")
  const [open, setOpen] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  })

  const { vertical, horizontal } = open

  const validacaoFormulario = Yup.object().shape({
    grupoLoja: Yup.string().required(t("form_filtro.loja")),
  })

  React.useEffect(() => {
    const carregarLojasDaRede = async () => {
      const { data } = await apiLojasDaRede()

      setLojasDaRede(data?.map((lojas: any) => ({
        label: `${lojas?.numLoja} - ${lojas?.loja}`,
        value: `${lojas?.numLoja}`
      })))
    }
    carregarLojasDaRede()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (abrirFiltro === true) {
      setTab(numeroTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abrirFiltro])

  const chipData: ChipData[] = [
    { key: 0, label: "GERAL", value: "GERAL" },
    { key: 1, label: "PERSONALIZADA", value: "PERSONALIZADA" }
  ]

  const abrirModalFiltro = () => {
    setAbrirFiltro(true)
  }

  const fecharModalFiltro = () => {
    setAbrirFiltro(false)
  }

  const opcaoEscolhida = (chipEscolhido: ChipData) => {
    setOpcao({ tipoContagem: chipEscolhido.value })
  }

  const dataInicioEscolhida = (date: any) => {
    setDataInicio(date);
  }

  const dataFimEscolhida = (date: any) => {
    setDataFim(date);
  }

  const carregarEquipamentos = async (textoDigitado: string, opcoesPrevias: string | any[]) => {
    let pagina = parseInt("0", 10)
    let dados: any[] = []

    await esperar(2000)
    let opcoesFiltradas = []

    if (!textoDigitado) {
      const { data, code, errors }: any = await apiBuscarGrupoEquipamentosSemDescricao(codLoja()!, "0", "100", "nomegru%2Casc")
      if (code === 200) {
        opcoesFiltradas = data?.items?.map((dados: any) => ({ value: dados.codigo, label: dados.descricao }))
      } else {
        notificaAirbrake(`Erro ao carregar equipamentos: ${errors}`, 'erro', 'ComponenteFiltro')
      }
    } else {
      await esperar(1000)
      const textoEmMinusculo = textoDigitado.toLowerCase()
      await esperar(1000)

      if (textoEmMinusculo.length > 2) {
        const { data, code, errors }: any = await apiBuscarGrupoEquipamentos(codLoja()!, textoEmMinusculo, "0", "100", "nomegru%2Casc")
        if (pagina <= data.totalPages && code === 200) {
          for (let i = 0; i < data.totalPages; i++) {
            if (!isEmpty(data?.items)) {
              dados = data?.items?.map((dados: any) => ({ value: dados.codigo, label: dados.descricao }))
            }
          }
        } else {
          notificaAirbrake(`Erro ao carregar equipamentos: ${errors}`, 'erro', 'ComponenteFiltro')
        }
      }

      opcoesFiltradas = dados.filter(({ label }: any) =>
        label.toLowerCase().includes(textoEmMinusculo)
      )
    }

    const hasMore = opcoesFiltradas.length > opcoesPrevias.length + 10
    const slicedOptions = opcoesFiltradas.slice(
      opcoesPrevias.length,
      opcoesPrevias.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    }
  }

  const aplicarFiltros = async (dados: any) => {

    dispatch(buscarHistoricoDeContagens({ items: [], limit: 10, totalItems: 0, totalPages: 0 }))
    dispatch(buscarMinhasContagensEncerradas({ items: [], limit: 10, totalItems: 0, totalPages: 0 }))
    // dispatch(tabCorrenteAtiva({ numeroTab: 1 }))

    try {
      await validacaoFormulario.validate(dados, { abortEarly: false })

      if (dataInicio !== null && dataFim === null) {
        setErro(t("form_filtro.data_inicial"))
        setOpen({ ...open, open: true })
      } else if (dataInicio === null && dataFim !== null) {
        setErro(t("form_filtro.data_final"))
        setOpen({ ...open, open: true })
      } else {
        setAplicandoFiltro(true)

        dispatch(aplicarFiltrosNasListas({
          ...dados,
          tipoContagem: opcao.tipoContagem,
          fimPeriodoContagem: dataFim !== null ? converterData(new Date(dataFim).toISOString()).split("/").reverse().join("-") : undefined,
          inicioPeriodoContagem:  dataInicio !== null ? converterData(new Date(dataInicio).toISOString()).split("/").reverse().join("-") : undefined,
        }))
        dispatch(atualizaPaginaHistorico({ paginaHistorico: 0, paginaEncerradas: 0 }))
        dispatch(atualizaPaginaEncerradas({ paginaHistorico: 0, paginaEncerradas: 0 }))

        await esperar(1000)
          // dispatch(tabCorrenteAtiva({ numeroTab: tab }))
          if (tab === 0 && setAbrirEncerrada === false) {
            dispatch(abrirEncerrada({ setAbrirEncerrada: true }))
          }
          setAplicandoFiltro(false)
          setAbrirFiltro(false)
      }

    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const message: any = {}

        error.inner.forEach(err => {
          message[err.path!] = err.message
        })

        formRef.current.setErrors(message)
      }
    }
  }

  const removerFiltro = async () => {
    setAplicandoFiltro(true)
    await esperar(5000)
    setAbrirFiltro(false)
    window.location.href = "/minhas-contagens"
  }

  const fecharSnack = () => {
    setOpen({ ...open, open: false })
  }

  return (
    <>
      <Button
        startIcon={<IconeFiltro />}
        onClick={abrirModalFiltro}
        className="Botao-Filtrar"
      >
        Filtrar
      </Button>
      <Drawer
        variant="persistent"
        anchor="bottom"
        className="Drawer-Filtro"
        open={abrirFiltro}
      >
        <div>
          <Button onClick={fecharModalFiltro} className="Botao-Arrastar-Filtro"></Button>
        </div>
        <div className="Titulo-Botao-Fechar-Filtro">
          <Typography className="Titulo-Modal-Filtro">Filtrar</Typography>
          <IconButton className="Botao-Fechar-Filtro" onClick={fecharModalFiltro}>
            <HighlightOff />
          </IconButton>
        </div>
        <div className="Form-Filtro">
          <Form ref={formRef} onSubmit={aplicarFiltros}>
            <div className="Campo-Loja-Filtro">
              <Typography className="Titulo-Campos">{t("nova_contagem.loja")}</Typography>
              <Select
                name="grupoLoja"
                placeholder="Loja"
                options={lojasDaRede}
                defaultValue={{value: codLoja(), label: lojaPrincipal()}}
                hasIcon={true}
                icon={<StoreOutlined className="Icone-Busca-Select" />}
              />
            </div>
            <div className="Campo-Datas-Filtro">
              <Typography className="Titulo-Campos">{t("form_filtro.data_inicio")}</Typography>
              <MaterialDatePicker
                name="inicioPeriodoContagem"
                onChange={dataInicioEscolhida}
                value={dataInicio}
                className="DatePicker-Filtro"
              />
            </div>
            <div className="Campo-Datas-Filtro">
              <Typography className="Titulo-Campos">{t("form_filtro.data_encerramento")}</Typography>
              <MaterialDatePicker
                name="fimPeriodoContagem"
                className="DatePicker-Filtro"
                onChange={dataFimEscolhida}
                value={dataFim}
              />
            </div>
            <div className="Campo-Tipo-Filtro">
              <Typography className="Titulo-Campos">Tipo</Typography>
              <div className="Chips-Filtros">
                {chipData.map((data) => (
                  <div key={data.key}>
                    <ChipFlutuante
                      label={data.label}
                      select={opcao?.tipoContagem === data.value}
                      onClick={() => opcaoEscolhida(data)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="Campo-Grupo-Filtro">
              <Typography className="Titulo-Campos">Grupo</Typography>
              <MultipleSelect
                name="grupoEquipamentos"
                placeholder={t("nova_contagem.placeholder")}
                loadingMessage={() => "Carregando"}
                maxMenuHeight={120}
                loadOptions={carregarEquipamentos}
              />
            </div>
            <div className="Botoes-Filtro">
              {aplicandoFiltro ?
                <CircularProgress
                  size={50}
                  variant="indeterminate"
                  className="Progresso-Circular-Filtro"
                /> :
                <>
                  <Button className="Botao-Remover-Filtro" onClick={removerFiltro}>{t("form_filtro.remover")}</Button>
                  <Button className="Botao-Aplicar-Filtro" type="submit">Filtrar</Button>
                </>
              }
            </div>
            <SnackBar
              className="Snackbar-Nova"
              open={open.open}
              mensagem={erro}
              onClose={fecharSnack}
              autoHideDuration={8000}
              anchorOrigin={{ vertical, horizontal }}
              action={
                <IconButton
                  color="inherit"
                  onClick={fecharSnack}
                >
                  <Close fontSize="small" />
                </IconButton>
              }
            />
          </Form>
        </div>
      </Drawer>
    </>
  )
}
