import React, { ReactNode } from 'react'
import { Modal, Typography, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import './ModalAvisoConexao.scss'

interface Props {
  abrir: boolean
  fechar: () => void
  content?: ReactNode | any
}

export function ModalNotificacao({abrir, fechar, content}: Props) {

  const { t } = useTranslation()

  return (
    <div className="Modal-Container-Notificacao">
      <Modal
        open={abrir}
        onClose={fechar}
        className="Modal-Descarte-Notificacao"
      >
        <div className="Modal-Paper-Notificacao">
          <div className="Botao-Voltar-Texto-Notificacao">
            <IconButton onClick={fechar} className="Botao-Voltar">
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography className="Informativo-Notificacao">{t("modal_conexao.primeiro_alerta")}</Typography>
          </div>
          <Typography className="Alerta-Notificacao">{t("modal_conexao.segundo_alerta")}</Typography>
          <div className="Content-Actions">
            {content}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ModalNotificacao
