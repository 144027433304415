import React from 'react'
import { KeyboardDatePicker } from "@material-ui/pickers"
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

interface Props {
  className?: string
  name?: string
  disabledPast?: boolean
  value?: any
  onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void
  inputVariant?: "filled" | "outlined" | "standard"
}

function ComponenteDatePicker(props: Props) {

  return (
    <div className="Container-DatePicker">
      <KeyboardDatePicker
        name={props.name}
        className={props.className}
        disablePast={props.disabledPast}
        margin="none"
        fullWidth
        mask='__/__/____'
        format='dd/MM/yyyy'
        placeholder='dd/mm/yyyy'
        value={props.value}
        onChange={props.onChange}
        variant="dialog"
        inputVariant={props.inputVariant}
        cancelLabel="CANCELAR"
        okLabel="CONFIRMAR"
      />
    </div>
  )
}

export const MaterialDatePicker = React.memo(ComponenteDatePicker)
