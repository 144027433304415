import React from 'react';
import { IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Cabecalho } from './Cabecalho'
import { Corpo } from './Corpo'
import { Rodape } from './Rodape'
import './MenuLateral.scss'

export function MenuLateral() {
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (booleanValue: boolean) => () => {
    setOpen(booleanValue);
  };

  return (
    <>
      <IconButton
        edge="start"
        className="Botao-Menu"
        color="primary"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      {/*Procurar estilo para arredondar o drawer */}
        <Drawer
          className="Drawer-Menu"
          anchor="left"
          open={open}
          onClose={toggleDrawer(false)}
        >
          <Cabecalho/>
          <Corpo />
          <Rodape/>
        </Drawer>

    </>
  );
}
