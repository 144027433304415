import React from 'react';
import { Grid } from '@material-ui/core';
import { Login } from '../../components'

import './PaginaInicial.scss';

export const PaginaInicial: React.FC = () => (
  <Grid
    container
    className="PaginaIncial-container"
  >
    <Grid
      item
      className="PaginaInicial-item"
    >
      <Login></Login>
    </Grid>
  </Grid>
);

export default PaginaInicial;
