import { MinhasContagensEncerradas } from '.'
import { MinhasContagensActionsTypes } from './actionTypes'

export const buscarMinhasContagensEncerradas = (payload: MinhasContagensEncerradas | any) => ({
  type: MinhasContagensActionsTypes.BUSCAR_CONTAGENS_ENCERRADAS,
  payload
})

export const adicionarMaisContagensEncerradas = (payload: MinhasContagensEncerradas | any) => ({
  type: MinhasContagensActionsTypes.ADICIONAR_MAIS_CONTAGENS,
  payload
})

export default {}
