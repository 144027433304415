import React from 'react'
//Serviços
import { MinhasContagensAtivas } from '../../../../redux/MinhasContagens/ativas'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
//Estilos
import { Typography, ListItem, List, Card, CardContent, CardActionArea } from '@material-ui/core'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { CardCarregamento } from '../../../'
import "./Ativas.scss"

export function Ativas(contagemAtiva: MinhasContagensAtivas) {

  const {t} = useTranslation()
  const history = useHistory()
  const [emCarregamento, setEmCarregamento] = React.useState(false)

  const estaEmCarregamento = () => {
    if(contagemAtiva.contagemAtiva.id === undefined) {
      setEmCarregamento(true)
    }

    setTimeout(() => {
      setEmCarregamento(false)
    }, 1500)
  }

  React.useEffect(() => {
    estaEmCarregamento()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const abrirContagemAtiva = () => {
    history.push("/contagem-em-andamento")
  }

  return (
    <>
      {emCarregamento ? <CardCarregamento active={true}/> :
        <List component="div" disablePadding>
          {!contagemAtiva.contagemAtiva.id ?
            <Typography className="Sem-Contagem">{t("contagens.sem_ativas")}</Typography>
            :
            <>
              <ListItem className="List-Cards-Ativas" disableGutters divider>
                <Card className="Cards">
                  <CardActionArea onClick={abrirContagemAtiva}>
                    <CardContent className="Card-Content">
                      <Typography className="Data-Contagem-Ativas">{contagemAtiva?.contagemAtiva.dataInicio}</Typography>
                      <Typography className="Nome-Codigo-Loja">
                        {`${contagemAtiva.contagemAtiva.nomeLoja} / ${contagemAtiva.contagemAtiva.codLoja}`}
                      </Typography>
                      <div className="Segunda-Linha-Ativas">
                        <Typography className="Nome-Usuario-Ativas">
                          {contagemAtiva.contagemAtiva?.nomeFuncionario?.toLowerCase().replace(/(?:^|\s)\S/g, function (text) {
                            return text?.toUpperCase();
                          })}
                        </Typography>
                        <KeyboardArrowRightIcon className="icone-seta-card"/>
                      </div>
                      <div className="Terceira-Linha-Ativas">
                        <Typography className="Tipo-Contagem-Ativas">
                          {contagemAtiva.contagemAtiva.tipoContagem === "PERSONALIZADA" ? "PERSONALIZADA" : "GERAL"}
                        </Typography>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </ListItem>
            </>
          }
        </List>
      }
    </>
  )
}

export default Ativas
