import React from 'react'
//Services
import { apiContagens } from '../../../services/Contagens'
import { buscarHistoricoDeContagens, atualizaHistoricoContagem } from '../../../redux/HistoricoContagens/action'
import { HistoricoContagens as InterfaceHistorico } from '../../../redux/HistoricoContagens'
import { PaginaInterface } from '../../../redux/Paginas'
import { atualizaPaginaHistorico } from '../../../redux/Paginas/action'
import { Filtro } from '../../../redux/Filtro'
import { notificaAirbrake } from '../../../config/Airbrake'
//Helpers
import { isEmpty } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { esperar } from '../../../helpers/helpers'
//Componentes
import { Typography, ListItem, List, Card, CardContent, CardActionArea } from '@material-ui/core'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { CardCarregamento } from '../../CardCarregamento'
import InfiniteScroll from "react-infinite-scroller"
import './HistoricoContagens.scss'

export function HistoricoContagens() {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { historicoDeContagens } = useSelector((state: InterfaceHistorico) => state)
  const { filtros } = useSelector((state: Filtro) => state)
  const { paginaHistorico } = useSelector((state: PaginaInterface) => state.pagina)
  const [temMaisContagens, setTemMaisContagens] = React.useState(true)

  let totalPages = historicoDeContagens?.totalPages

  React.useEffect(() => {
    const carregarHistoricoContagens = async () => {
      dispatch(buscarHistoricoDeContagens({ items: [], limit: 10, totalItems: 0, totalPages: 0 }))
      dispatch(atualizaPaginaHistorico({ paginaHistorico: 0, paginaEncerradas: 0 }))

      await esperar(1000)
      apiContagens(
        filtros.grupoLoja,
        filtros.fimPeriodoContagem,
        filtros.grupoEquipamentos,
        "",
        filtros.grupoLoja,
        filtros.inicioPeriodoContagem,
        paginaHistorico.toString(),
        "10",
        "FINALIZADO",
        filtros.tipoContagem
      ).then(resposta => {
        const { data } = resposta
        if (data?.totalItems === 0 || data?.totalItems <= 10) {
          setTemMaisContagens(false)
        } else if (historicoDeContagens.items.length === 0) {
          setTemMaisContagens(false)
        }

        dispatch(buscarHistoricoDeContagens(data))
      })
        .catch(error => {
          notificaAirbrake(`ChamadaApiHistoricoContagens: ${error}`, 'erro', 'ContagensEncerradasHistorico')
          setTemMaisContagens(false)
        })
    }
    carregarHistoricoContagens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros])

  const carregarMaisContagens = async () => {
    if (paginaHistorico <= totalPages) {
      setTemMaisContagens(false)

      apiContagens(
        filtros.grupoLoja,
        filtros.fimPeriodoContagem,
        filtros.grupoEquipamentos,
        "",
        filtros.grupoLoja,
        filtros.inicioPeriodoContagem,
        (paginaHistorico + 1).toString(),
        "10",
        "FINALIZADO",
        filtros.tipoContagem
      ).then(resposta => {
        const { data } = resposta
        if (!isEmpty(data?.items)) {
          let maisPagina = paginaHistorico <= totalPages
          setTemMaisContagens(maisPagina)
          dispatch(atualizaHistoricoContagem(data))
          dispatch(atualizaPaginaHistorico({ paginaHistorico: paginaHistorico + 1, paginaEncerradas: 0 }))
        }
      }).catch(error => {
        notificaAirbrake(`ChamadaApiHistoricoContagens: ${error}`, 'erro', 'ContagensEncerradasHistorico, CarregarMaisContagens')
        setTemMaisContagens(false)
      })
    }
  }

  const visualizarContagem = (contagem: any) => {
    localStorage.setItem("contagem_id", contagem.id)
    localStorage.setItem("loja_contagem_encerrada", contagem.codLoja)
    history.push("/visualizar-contagem")
  }

  return (
    <div className="Scroll-Infinita-Historico">
      <InfiniteScroll
        initialLoad={false}
        loadMore={carregarMaisContagens}
        loader={
          <div
            key={0}
          >
            <CardCarregamento active={false} />
          </div>
        }
        hasMore={temMaisContagens}
        useWindow={false}
      >
        <List component="div" disablePadding className="List-Container-Historico">
          {historicoDeContagens?.items?.length === 0 && historicoDeContagens?.totalPages === 0 && temMaisContagens === false ?
            <Typography className="Sem-Historico-Contagens">Nenhuma contagem encerrada até o momento!</Typography>
            :
            historicoDeContagens?.items?.length !== 0 && historicoDeContagens?.items?.map((dados: any) => (
              <ListItem disableGutters divider className="List-Card-Historico-contagens" key={dados?.id}>
                <Card className="Cards-Historico-contagens">
                  <CardActionArea disabled={dados?.existeDivergencia === false} onClick={() => visualizarContagem(dados)}>
                    <CardContent className="Card-Content-Historico-contagens">
                      <Typography className="Data-Historico-contagens">{dados?.dataInicio}</Typography>
                      <Typography className="Nome-Codigo-Loja-Historico">
                        {`${dados?.nomeLoja} / ${dados.codLoja}`}
                      </Typography>
                      <div className="linha-usuario-seta">
                        <Typography className="Nome-Usuario-Historico">
                          {dados?.nomeFuncionario?.toLowerCase().replace(/(?:^|\s)\S/g, function (text: any) {
                            return text?.toUpperCase();
                          })}
                        </Typography>
                        <KeyboardArrowRightIcon className='icone-seta-card'/>
                      </div>
                      <div className='linha-status-historico-contagens'>
                        {dados?.existeDivergencia ?
                          <Typography className="Com-Divergencia-Historico">{t("contagens.com_divergencia")}</Typography>
                          :
                          <Typography className="Sem-Divergencia-Historico">{t("contagens.sem_divergencia")}</Typography>
                        }
                        <Typography className="Tipo-Contagem-Historico">
                          {dados?.tipoContagem === "PERSONALIZADA" ?
                            t("contagens.personalizada") :
                            t("contagens.geral")
                          }
                        </Typography>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </ListItem>
            ))
          }
        </List>
      </InfiniteScroll>
    </div>
  )
}

export default HistoricoContagens
