import { Reducer } from 'redux'
import { LojasNaRede } from './actionTypes'

export interface LojasRede {
  listaLojasNaRede: {
    label: string
    value: string
  }[]
}

const estadoInicial: any = [{
  label: '',
  value: ''
}]

const BuscarLojasDaRede: Reducer<LojasRede> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case LojasNaRede.BUSCAR_LOJAS_REDE: {
      return action.payload
    }
    default:
      return state
  }
}

export default BuscarLojasDaRede
