import axios from 'axios';

const obterEndpointApi = () => {
  if (!process.env.REACT_APP_API_BASE_URL) {
    throw new Error('Não é possível realizar requisições. Endpoint da API não está configurado.');
  }

  return process.env.REACT_APP_API_BASE_URL;
};

const instanciaAxios = axios.create({
  baseURL: obterEndpointApi(),
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'X-Auth-Api': process.env.REACT_APP_CHAVE_AUTORIZACAO_API,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, HEAD, OPTIONS, DELETE',
  },
});

export default instanciaAxios;
