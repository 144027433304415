import { Reducer } from 'redux'
import { converterData } from '../../helpers/helpers'
import { HistoricoContagensActionTypes } from './actionTypes'

export interface DadosUsuario {
  codLoja: string | undefined,
  dataInicio: string | undefined,
  existeDivergencia: boolean | undefined,
  id: string | undefined,
  nomeFuncionario: string | undefined,
  nomeLoja: string | undefined,
  tipoContagem: string | undefined
}

export interface HistoricoContagens {
  historicoDeContagens: {
    items: Array<DadosUsuario>,
    limit: number,
    totalItems: number,
    totalPages: number,
  }
}

export interface Historico {
  items: Array<DadosUsuario>,
  limit: number,
  totalItems: number,
  totalPages: number,
}

const estadoInicial: any = {
  items: [],
  limit: 10,
  totalItems: 0,
  totalPages: 0
}

const BuscarHistoricoContagens: Reducer<HistoricoContagens | any> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case HistoricoContagensActionTypes.BUSCAR_HISTORICO_CONTAGENS: {
      return {
        ...action.payload,
        items: action.payload?.items?.map((dados: any) => ({...dados, dataInicio: converterData(dados.dataInicio)}))
      }
    }
    case HistoricoContagensActionTypes.ADICIONAR_MAIS_CONTAGEM: {
      return {
        ...state,
        items: action.payload.items.map((dados: any) => ({...dados, dataInicio: converterData(dados.dataInicio)})).forEach((itens: any) => {state?.items.push(itens)}),
        ...state,
      }
    }
    default:
      return state
  }
}

export default BuscarHistoricoContagens
