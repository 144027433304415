import { AxiosPromise, AxiosRequestConfig } from 'axios';
import api from '../../infraestructure/api'

interface Error {
  message: Array<object>
}

export const resgatarContagensFiltradas = (
    grupoLoja: string,
    fimPeriodoContagem?: string,
    grupoEquipamentos?: string,
    idFuncionario?: string,
    idLoja?: string,
    inicioPeriodoContagem?: string,
    page?: string,
    size?: string,
    statusContagem?: string,
    tipoContagem?: string
  ):AxiosPromise<Object> => {

  const dataFim = fimPeriodoContagem ? "fimPeriodoContagem="+fimPeriodoContagem+"&" : ""
  const grupoEquip = grupoEquipamentos ? "grupoEquipamentos="+grupoEquipamentos+"&" : ""
  const idFunc = idFuncionario ? "idFuncionario="+idFuncionario+"&" : ""
  const loja = idLoja ? "idLoja="+idLoja+"&" : ""
  const dataInicio = inicioPeriodoContagem ? "inicioPeriodoContagem="+inicioPeriodoContagem+"&" : ""
  const pagina = page ? "page="+page+"&" : ""
  const tamanho = size && "size="+size
  const status = statusContagem && "&statusContagem="+statusContagem
  const tipo = tipoContagem && ("&tipoContagem="+tipoContagem)

  const urlVerificada = `${dataFim}${grupoEquip}${idFunc}${loja}${dataInicio}${pagina}${tamanho}${status}${tipo}`

  const url = `ccl-api-cadastros/ativos/contagem-cega/v1/contagens/loja/grupo/${grupoLoja}?`
  const urlContagem = `${url}${urlVerificada}`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: urlContagem.trim()
  }

  return api(parametros);
}

export const apiContagens = async (
    grupoLoja: string,
    fimPeriodoContagem?: string,
    grupoEquipamentos?: string,
    idFuncionario?: string,
    idLoja?: string,
    inicioPeriodoContagem?: string,
    page?: string,
    size?: string,
    status?: string,
    tipoContagem?: string) => {

  try {
    const resposta = await resgatarContagensFiltradas(
      grupoLoja,
      fimPeriodoContagem,
      grupoEquipamentos,
      idFuncionario,
      idLoja,
      inicioPeriodoContagem,
      page,
      size,
      status,
      tipoContagem
    )

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export default {}
