import React from 'react'
import { primeiraMaiuscula } from '../../../helpers/helpers'
import { usuarioLogado } from '../../../infraestructure/auth'
import { MenuLateral } from '../../MenuLateral'
import './HeaderContagens.scss'

export function HeaderContagens() {
  return (
    <header className="header-contagens-container">
      <h1 className="user">Olá, {primeiraMaiuscula(usuarioLogado()?.split(" ")?.[0])}!</h1>
      <MenuLateral />
    </header>
  )
}

export default HeaderContagens
