import React from 'react';

const Logo = () => {
  return (
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.8389 0H8.8117C8.09645 0.0328767 7.38118 0.690409 7.28789 1.44657L5.5464 17.1288C5.4531 17.8849 5.95066 18.5096 6.66592 18.5096H9.06049C9.77574 18.5096 10.4599 17.8849 10.5532 17.1288L11.2374 10.9151C11.3307 10.1589 11.9215 9.17261 12.5746 8.77809L17.0216 5.98357C17.6747 5.55617 18.6698 5.55617 19.2607 5.98357L23.0236 8.74521C23.5833 9.1726 23.9876 10.126 23.8943 10.8822L23.1791 17.1945C23.0858 17.9836 22.4327 18.6082 21.6863 18.6082H11.7038C10.9886 18.6082 10.3044 19.2329 10.2422 19.989L9.96233 22.5205C9.86904 23.2767 10.3977 23.9014 11.113 23.9014H26.1645C26.8797 23.9014 27.5639 23.2767 27.6261 22.5205L29.9896 1.34795C30.0829 0.62466 29.5542 0 28.8389 0Z" fill="#043D94" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.0106869 22.6191C-0.0826077 23.3752 0.446065 23.9999 1.16132 23.9999H3.52479C4.24005 23.9999 4.8931 23.3752 4.9864 22.6191L5.26628 20.0547C5.35957 19.2985 4.83091 18.6738 4.11565 18.6738H1.75219C1.03693 18.6738 0.383863 19.2985 0.290569 20.0547L0.0106869 22.6191Z" fill="#043D94" />
    </svg>
  )
}

export default Logo;
