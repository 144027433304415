export interface Itens {
  codEquipamento: string
  codGrupo: string
  codSubgrupo: string
  descricaoEquipamento: string
  nomeGrupo: string
  nomeSubgrupo: string
  possuiNumeroSerie: true | null
  quantidadeAfastada: number
  quantidadeAguardandoDevolucao: number
  quantidadeComprometida: number
  quantidadeContada: number
  quantidadeDisponivel: number
  quantidadeNoSistema: number
  quantidadeReservada: number
  quantidadeSubstituiu: number
}

export interface ContagemEncerrada {
  dataFim: string,
  dataInicio: string,
  funcionario: { id: number, nome: string },
  id: string,
  itensPrevistos: Array<Itens>,
  loja: { codGrupo: string, codLoja: string, nome: string },
  statusContagem: string,
  tipoContagem: string,
}

export const estadoInicial: ContagemEncerrada = {
  dataFim: '',
  dataInicio: '',
  funcionario: {id: 0, nome: ''},
  id: '',
  itensPrevistos: [],
  loja: {codGrupo: '', codLoja: '', nome: ''},
  statusContagem: '',
  tipoContagem: ''
}
