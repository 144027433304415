import { AxiosPromise, AxiosRequestConfig } from 'axios';
import api from '../../infraestructure/api'
import { codLoja } from '../../infraestructure/auth'

interface Error {
  message: Array<object>
}

const codigoLoja = codLoja()

export const resgatarOutrasContagensAtivas = (cod_loja: string, page?: string, size?: string):AxiosPromise<Object> => {
  const urlOutras = `ccl-api-cadastros/ativos/contagem-cega/v1/contagens/loja/grupo/${cod_loja}?page=${page}&size=${size}&statusContagem=ATIVO`

  const parametros:AxiosRequestConfig = {
    method: 'GET',
    url: urlOutras
  }

  return api(parametros);
}

export const apiOutrasContagensAtivas = async (page?: string, size?: string) => {
  try {
    const resposta = await resgatarOutrasContagensAtivas(codigoLoja!, page, size)

    if(resposta.status === 200) {
      return resposta.data
    }

    return resposta.status
  } catch (error: Error | any) {
    return error.message
  }
}

export default {}
