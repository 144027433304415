import React, { useRef, useEffect, useState } from "react"
import { AsyncPaginate } from "react-select-async-paginate"
import {
  components,
  OptionTypeBase,
  Props as SelectProps,
} from "react-select"
import { useField } from "@unform/core"
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import { IconButton } from '@material-ui/core'
import './MultipleSelect.scss'

interface Props extends SelectProps<OptionTypeBase, true> {
  name: string
}

export const MultipleSelect = ({ name, loadOptions, placeholder, loadingMessage, maxMenuHeight, minMenuHeight, ...rest }: Props) => {

  const selectRef = useRef(null)

  const { fieldName, defaultValue, registerField } = useField(name)
  const [value, onChange] = useState([])
  const [dataInput, setInputData] = useState("")

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        return ref.state.value.map((option: { value: any }) => option.value)
      }
    })
  }, [fieldName, registerField])

  const Menu = (props: any) => {
    return (
      <components.Menu {...props} className="Menu-Multiple-Select">
        {props.children}
      </components.Menu>
    )
  }

  const IndicatorSeparator = ({ innerProps }: any) => {
    return <span style={{width: "0.1px"}} {...innerProps} />
  }

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {dataInput === "" ?
          <SearchIcon className="Icone-Busca--Multple-Select" /> :
          <IconButton onClick={() => clearInputdata} disabled={dataInput === ""} className="Clear-Icon-Multiple-Select">
            <ClearIcon />
          </IconButton>
        }
      </components.DropdownIndicator>
    )
  }

  const ClearIndicator = (props: any) => {
    return (
      <components.ClearIndicator {...props} className="Clear-Indicator">
        <></>
      </components.ClearIndicator>
    )
  }

  const clearInput = (event: string) => {
    setInputData(event)
  }

  const clearInputdata = () => {
    setInputData("")
  }

  return (
    <AsyncPaginate
      isMulti
      selectRef={selectRef}
      closeMenuOnSelect={true}
      value={value}
      defaultValue={defaultValue}
      maxMenuHeight={maxMenuHeight}
      minMenuHeight={minMenuHeight}
      onChange={onChange}
      loadOptions={loadOptions}
      placeholder={placeholder}
      onInputChange={(e) => clearInput(e)}
      classNamePrefix="react-select"
      className="Input-Multiple-Select"
      loadingMessage={loadingMessage}
      components={{ Menu, DropdownIndicator, IndicatorSeparator, ClearIndicator }}
    />
  )
}
export default MultipleSelect
