import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IDivergencia } from '../../../redux/Divergencia'
import { Box, Typography } from '@material-ui/core'
import { modificarCodigo } from '../../../helpers/helpers'
import { ModalMaisDetalhes } from './ModalMaisDetalhes'
import { Header } from '../..'
import './DetalhesDivergencia.scss'
import BotaoObservacao from './BotaoObservacao'

export function DetalhesDivergencia() {

  const history = useHistory()

  const {
    codEquipamento,
    descricaoEquipamento,
    quantidadeNoSistema,
    quantidadeContada
  } = useSelector((state: IDivergencia) => state.divergencia)

  React.useEffect(() => {
    codEquipamento === undefined && history.push('/visualizar-contagem')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className="container-divergencia">
      <Header/>
      <Typography className="nome-equipamento">{descricaoEquipamento}</Typography>
      <Typography className="codigo-equipamento">{modificarCodigo(codEquipamento)}</Typography>
      <Box className="content-lista-acoes">
        <ol className="lista-ordenada">
          <li className="list-items">
            <Typography className="chave">Valor Sistema:</Typography>
            <p className="valor-positivo">{quantidadeNoSistema}</p>
          </li>
          <li className="list-items">
            <Typography className="chave">Valor Contado:</Typography>
            <p className="valor-positivo">{quantidadeContada}</p>
          </li>
          <li className="list-items">
            <Typography className="chave">Diferença:</Typography>
            <p className={quantidadeNoSistema - quantidadeContada > 0 ? "valor-negativo" : "valor-positivo"}>{quantidadeNoSistema - quantidadeContada}</p>
          </li>
        </ol>
        <ModalMaisDetalhes />
        <BotaoObservacao />
      </Box>
    </Box>
  )
}

export default DetalhesDivergencia
