import { Reducer } from 'redux'
import { MinhasContagensActionsTypes } from './actionTypes'
import { converterData } from '../../../helpers/helpers'

export interface DadosUsuario {
  codLoja: string | undefined,
  dataInicio: string | undefined,
  existeDivergencia: boolean | undefined,
  id: string | undefined,
  nomeFuncionario: string | undefined,
  nomeLoja: string | undefined,
  tipoContagem: string | undefined
}

export interface MinhasContagensEncerradas {
  contagensEncerradas: {
    items: Array<DadosUsuario>,
    limit: number,
    totalItems: number,
    totalPages: number,
  }
}

const estadoInicial: any = {
    items: [],
    limit: 10,
    totalItems: 0,
    totalPages: 0
}

const BuscarContagensEncerradas: Reducer<MinhasContagensEncerradas | any> = (state = estadoInicial, action: any) => {

  switch (action.type) {
    case MinhasContagensActionsTypes.BUSCAR_CONTAGENS_ENCERRADAS: {
      return {
        ...action.payload,
        items: action.payload?.items?.map((dados: any) => ({...dados, dataInicio: converterData(dados.dataInicio)}))
      }
    }
    case MinhasContagensActionsTypes.ADICIONAR_MAIS_CONTAGENS: {
      return {
        ...state,
        items: action.payload.items.map((dados: any) => ({...dados, dataInicio: converterData(dados.dataInicio)})).forEach((itens: any) => {state?.items.push(itens)}),
        ...state,
      }
    }
    default:
      return state
  }
}

export default BuscarContagensEncerradas
