import { HistoricoContagens } from '.'
import { HistoricoContagensActionTypes } from './actionTypes'

export const buscarHistoricoDeContagens = (payload: HistoricoContagens | any) => ({
  type: HistoricoContagensActionTypes.BUSCAR_HISTORICO_CONTAGENS,
  payload
})
export const atualizaHistoricoContagem = (payload: HistoricoContagens | any) => ({
  type: HistoricoContagensActionTypes.ADICIONAR_MAIS_CONTAGEM,
  payload
})

export default {}
